.user_home {
    display: flex;
  }
  
  .user_home .homeContainer {
    flex: 6;
  }
  
  .userWelcomeContainer {
    display: flex;
    justify-content: center;
    overflow: hidden;
    width: 90%;
    align-items: center;
    margin-top: 20px;
    margin: auto;
    position: relative;
  }
  
  .carousel {
    display: flex;
    width: 300%; /* 100% per slide, adjust if different */
    transition: transform 0.5s ease-in-out;
  }
  
  .userWelcomeCard {
    flex: 0 0 100%; /* Full width for each slide */
    padding: 20px;
    box-sizing: border-box;
  }
  
  .userWelcome {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .userWelcomeImg img {
    width: 90%;
    height: auto;
    border-bottom: 1px solid #ddd;
  }
  
  .userWelcomeText {
    padding: 20px;
    padding-right: 30px !important;
    /* text-align: center; */
    width: 60%;
  }
  
  .userWelcomeText h3 {
    font-size: 35px;
    margin-bottom: 15px;
    font-weight: 600;
    color: #007bff;
  }

  .userWelcomeImg{
    width: 40%;
  }
  
  .userWelcomeText p {
    font-size: 20px;
    color: gray;
    margin-bottom: 10px;
  }
  
  /* Retain existing button styles */
  .userbtn {
    --color: #0077ff;
    font-family: inherit;
    display: inline-block;
    width: 8em;
    height: 2.9em;
    line-height: 2.5em;
    overflow: hidden;
    cursor: pointer;
    margin: 16px;
    margin-left: 0 !important;
    font-size: 17px;
    z-index: 1;
    color: var(--color);
    border: 2px solid var(--color);
    border-radius: 40px;
    position: relative;
    background: #0077ff;
    color: white;
  }
  
  .userbtn::before {
    position: absolute;
    content: "";
    background: #0048ff;
    width: 150px;
    height: 200px;
    z-index: -1;
    border-radius: 50%;
  }
  
  .userbtn:hover {
    color: white;
  }
  
  .userbtn:before {
    top: 100%;
    left: 100%;
    transition: 0.3s all;
  }
  
  .userbtn:hover::before {
    top: -80px;
    left: -10px;
  }
  
  @media (max-width: 850px) {
    .user_home .homeContainer {
      width: 90%;
    }
  
    .userWelcomeContainer {
      overflow: hidden;
    }
  
    .carousel {
      display: flex;
      width: 100%; /* Adjust to ensure it fits within the container */
      transition: transform 0.5s ease-in-out;
    }
  
    .userWelcomeCard {
      flex: 0 0 100%; /* Full width for each card */
      max-width: 100%; /* Ensure each card takes up full width */
      margin: 0 auto; /* Center the card horizontally */
    }
  
    .userWelcome {
      flex-direction: column; /* Stack image and text vertically on small screens */
      align-items: center;
      padding: 20px; /* Optional: add padding for better spacing */
    }
  
    .userWelcomeImg {
      width: 90%; /* Full width for the image container */
    }
  
    .userWelcomeImg img {
      width: 100%; /* Adjusted width for better fit */
      height: auto;
    }
  
    .userWelcomeText {
      width: 100%; /* Full width for text container */
      text-align: center;
    }
  
    .userWelcomeText h3 {
      font-size: 24px; /* Adjusted font size */
    }
  
    .userWelcomeText p {
      font-size: 17px; /* Adjusted font size */
    }

    .userbtn{
      margin-right: 0 !important;
    }
  }

  @media (max-width: 1100px){
    .user_home .homeContainer {
      width: 90%;
    }
  }
  
  
  