@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400..900&display=swap');

/* Full-screen overlay */
.timer_popup {
  font-family: "Orbitron", sans-serif; /* Apply Orbitron font to all text */
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's on top */
}

/* Popup content */
.timer_popup_content {
  background: #fff;
  border-radius: 20px;
  padding: 40px;
  text-align: center;
  position: relative;
  width: 80vw;
  max-width: 600px;
  height: auto;
  max-height: 80vh;
  overflow-y: auto;
  font-family: "Orbitron", sans-serif; /* Apply Orbitron font to content */
}


/* Timer display */
.timer_display {
  font-size: 3rem;
  font-weight: bold;
  margin-top: 20px;
  font-family: "Orbitron", sans-serif; /* Apply Orbitron font to timer display */
}
