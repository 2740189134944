@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&display=swap');

.terms_image_container {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    padding-top: 30px;
    padding-bottom: 20px;
    font-family: "Poppins", sans-serif;
    background-color: #e1e4e7;
    margin-top: 60px;
}

.terms_text {
    flex: 1;
    padding-right: 100px;
    padding-left: 50px;
}

.terms_heading {
    font-size: 2rem;
    margin-bottom: 0.8rem;
    color: black;
    font-weight: 700 !important;
}

.terms_paragraph {
    font-size: 1rem;
    color: #666;
}

.terms_image {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e1e4e7;
    height: 400px;
    flex-shrink: 0;
    padding-right: 25px;
    padding-left: 100px;
}

.terms_image img {
    height: 100%;
    object-fit: cover;

}

.terms_bold_head {
    font-weight: 600;
    font-size: 25px;
}

.terms_mail {
    font-weight: 700;
    font-size: 17px;
    color: #6294ff;
    cursor: pointer;
}

.terms_mail:hover {
    color: #3776ff;
}

@media (max-width: 800px) {
    .terms_image_container {
        flex-direction: column;
    }

    .terms_text {
        padding-right: 20px;
        padding-left: 20px;
        margin-top: 50px;
        margin-bottom: 10px;
        text-align: center;
    }

    .terms_image {
        height: 200px;
        width: 100%;
        padding-right: 20px;
        padding-left: 20px;
        margin-top: 80px;
        padding-bottom: 50px;
    }

    .terms_image img {
        width: 100%;
        height: auto;
        margin-top: 40px;
    }
}