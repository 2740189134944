.consultation-details {
    display: flex;
  }
  
  .bookingconf_cont {
    padding: 20px;
    flex-grow: 1;
  }
  
  .booking_user_card {
    display: flex;
    align-items: center;
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
  }
  
  .bookimg_cont {
    flex-shrink: 0;
    margin-right: 20px;
  }
  
  .user-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .user_details {
    flex-grow: 1;
  }
  
  .therapist-name {
    font-size: 1.6rem;
    font-weight: bold;
    margin-bottom: 8px;
  }
  
  .therapist-subheading {
    font-size: 1.2rem;
    margin-bottom: 8px;
    color: #555;
  }
  
  .therapist-areas,
  .therapist-languages {
    font-size: 1rem;
    color: #666;
    margin-bottom: 8px;
  }
  
  .therapist-rating {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .consultationbill{
    margin: auto;
  }

  .consulcont{
    width: 100%;
  }

  .consultclient{
    margin: auto;
    margin-top: 30px;
  }

  .order_info_container {
    background-color: #fff;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 16px;
    width: 600px;
    margin: auto;
    margin-top: 30px;
  }
  
  .order-heading {
    font-size: 1.2em;
    font-weight: 700;
    margin-bottom: 8px;
    color: #2b2b2b;
  }
  
  .order-item {
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
  }
  
  .order-detail {
    color: #555;
  }
  
  .order-rating {
    display: flex;
    justify-content: space-between;
    padding: 12px 0;
  }
  
  .book-again-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    cursor: pointer;
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1em;
  }
  
  .book-again-button:hover {
    background-color: #1f6dff;
  }
  
  @media (max-width: 768px) {
    .consulthomecont{
        width: 80% !important;
    }

    .order_info_container{
        width: 100%;
    }

    .book-again-button{
        width: 50%;
        padding: 8px 0px;
    }
  }

  @media (max-width: 1100px) {
    .consulthomecont{
        width: 80% !important;
    }
}