@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

.consultant_home {
    display: flex;
    height: 100%;
}

.Consultant_home_container {
    flex: 1;
    width: 90%;
}

.consultant_call_heading {
    font-family: "Poppins", sans-serif !important;
    font-weight: 700 !important;
    color: #001845;
    padding: 30px 0px 0px 40px;
    font-size: 35px;
}