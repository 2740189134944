/* ConsultationCard.css */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

.tabs {
  display: flex;
  align-items: center;
  padding-top: 0px;
  margin-bottom: 10px;
  flex-wrap: wrap;
  margin-left: 15px;
}

.card-grid {
  display: flex;
  justify-content: space-around;
  margin: 20px 20px 20px 0;
  padding-top: 20px;
  flex-wrap: wrap;
}

.consultant_home {
  display: flex;
  height: 100%;
}

.Consultant_home_container {
  flex: 1;
  width: 90%;
}

.tab {
  padding: 10px 30px;
  line-height: 20px;
  cursor: pointer;
  transition: color 0.3s;
  color: black;
  font-size: 18px;
  font-weight: 500;
  position: relative;
}

.tab:hover {
  opacity: 80%;
}

.tab::after {
  content: '';
  position: absolute;
  bottom: -2px;
  /* Adjust as needed */
  left: 0;
  width: 0;
  height: 2px;
  background-color: #2b8ced;
  transition: width 0.3s ease;
}

.tab.active {
  color: #2b8ced;
}

.tab.active::after {
  width: 100%;
}

.consultant_call_heading {
  font-family: "Poppins", sans-serif !important;
  font-weight: 700 !important;
  color: #001845;
  padding: 30px 0px 0px 40px;
  font-size: 35px;
}

/* Existing styles here */

.no-bookings-message {
  text-align: center;
  font-size: 23px !important;
  color: #666;
  margin-top: 20px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-family: 'Poppins', sans-serif;
}


@media (max-width: 768px) {
  .tab-button {
    margin-top: 20px;
  }

  .tabs {
    margin-bottom: 0px;
  }

  .tab {
    margin-bottom: 20px;
  }
  .no-bookings-message{
    font-size: 20px !important;
  }
}
