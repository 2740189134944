.table {
    .info-icon {
        color: rgb(166, 166, 166);
        cursor: pointer;
        margin-left: 5px;
    }

    .utr-icon {
        color: #4f4f4f;
        cursor: pointer;
        margin-left: 5px;
        font-weight: normal;
        font-size: medium;
    }

    .tableCell {
        color: #4f4f4f;
        font-weight: bolder;
        font-size: larger;
        align-items: center;
    }

    .tableValue {
        color: rgb(64, 86, 109);        ;
        font-weight: 500;
        font-size: 0.945rem;
    }

    .tableRow {
        background-color: #f8fafc;
    }
}

.tableWrapper {
    position: relative;
    overflow-x: auto; // Enable horizontal scrolling
    width: 100%; 
}

.paginationContainer {
    margin: 20px 0;
    display: flex;
    justify-content: center;

    .btn {
        font-size: 18px !important;
        margin: 0 5px;
    }
}

.itemsPerPageContainer {
    position: absolute;
    right: 40px;
    bottom: 10px;

    .form-select {
        width: 100px;
    }
}

.css-pdct74-MuiTablePagination-selectLabel,.css-levciy-MuiTablePagination-displayedRows{
    margin-bottom: 0;
}

.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar{
    padding-left: 0 !important;
}



@media (maxWidth: 600px) {

    .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar{
        // display: none !important;;
        margin: auto;
    }

    .table .tableValue{
        font-size: 12px;
        padding: 5px;
    }

    .paginationContainer .btn {
        font-size: 14px !important;
    }

    .table {
        width: 100%; // Ensure the table takes full width of the wrapper
    }

    .list-container {
        width: 100%;
    }

    .tableWrapper {
        width: 100% !important; // Set width to 90% on mobile
        overflow-x: auto; 
    }

    .TableContainer {
        width: 100%; // Ensure the container takes full width of the wrapper
        overflow-x: auto; // Enable horizontal scrolling
    }

    .processedStatus {
        font-size: 9px !important; /* Adjusted font size for mobile view */
        padding:5px 3px !important;
        padding-right: 6px !important;
    }

    .unprocessedStatus {
        font-size: 9px !important; /* Adjusted font size for mobile view */
        padding:5px 7px !important;
       padding-right: 8px !important;
    }

    .table .utr-icon{
        font-size: smaller !important;
        margin-left: 3px !important;
    }

    .table .tableCell{
        font-size: 14px !important;
    }

    .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar{
        padding: 10px 20px !important;
    }

    .homeContainer{
        width: 90% !important;
        margin: auto !important;
        border-radius: 10px !important;
    }

 

  .css-ykiy2t-MuiPaper-root-MuiTableContainer-root{
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
