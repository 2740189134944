.booking-container {
  /* border: 1px solid #ccc; */
  border-radius: 8px;
  padding: 50px;
  margin-top: 16px;
  width: 90%;
  margin: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.booking-container .booking-heading {
  font-size: 1.9rem;
  font-weight: 600;
  margin-bottom: 8px;
}

.booking-container .session-mode-heading {
  font-size: 1.3rem;
  margin-bottom: 30px;
  margin-top: 40px;
  text-align: center;
}

.booking-container .mode-buttons {
  display: flex;
  gap: 40px;
  margin-bottom: 30px;
  align-items: center;
  justify-content: center;
}

.booking-container .mode-button {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  border-radius: 4px;
  border: 2px solid #2b8cd7;
  background-color: transparent;
  color: #2b8cd7;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  width: 15%;
  text-align: center;
  justify-content: center;
}

.booking-container .mode-button:hover {
  transition: all 0.3s ease-in-out 0s;
  transform: scale(1.1);
}

.booking-container .mode-button.selected {
  background-color: #2b8cd7;
  color: white;
}

.booking-container .icon {
  margin-right: 8px;
}

.booking-container .date-buttons {
  display: flex;
  width: 100%;
}


.booking-container .date-button {
  flex: 1 1 33%;
  padding: 8px 0;
  border: none;
  border-bottom: 2px solid transparent;
  background-color: transparent;
  cursor: pointer;
  text-align: center;
  transition: border-bottom 0.3s;
  background: rgb(239, 239, 239);
  margin-bottom: 30px;
  border-right: 1px solid rgba(255, 255, 255);
}

.booking-container .date-button:hover {
  background-color: #e0e0e0;
  transition: all 0.3s ease-in-out 0s;
  transform: scale(1.03);
}

.booking-container .date-button.selected {
  background-color: #e0e0e0;
  border-bottom: 2px solid #2b8cd7;
}

.booking-container .time-slots {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-bottom: 16px;
}

.booking-container .time-slot {
  padding: 8px;
  border-radius: 4px;
  border: 2px solid #ccc;
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.3s;
}

.booking-container .time-slot:hover {
  background-color: #f0f0f0;
  transition: all 0.3s ease-in-out 0s;
  transform: scale(1.03);
}

.booking-container .time-slot.selected {
  background-color: #2b8cd7;
  color: white;
  border: 1px solid #2b8cd7;
}

.booking-container .book-button {
  width: 100%;
  padding: 12px;
  border-radius: 4px;
  background-color: #2b8cd7;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s;
  border-color: #2b8cd7;
}

.booking-container .book-button:hover {
  font-weight: 700;
  transition: all 0.3s ease-in-out 0s;
  transform: scale(1.03);
}

.booking-container .popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.booking-container .popup-content {
  background-color: white;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.booking-container .popup-text {
  margin-bottom: 16px;
}

.booking-container .popup-actions {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.booking-container .popup-button {
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  transition: background-color 0.3s;
}

.booking-container .popup-button.yes {
  background-color: #007bff;
  color: white;
  width: 20%;
  border: none;
  margin-top: 30px;
}

.booking-container .popup-button.no {
  background-color: transparent;
  color: #007bff;
  border: 2px solid #007bff;
  margin-top: 30px;
  width: 20%;
}

.booking-container .popup-button:hover {
  opacity: 0.8;
}

@media (max-width: 768px) {
  .booking-container {
    padding: 20px;
  }

  .booking-container .mode-buttons {
    gap: 20px;
  }

  .booking-container .mode-button {
    width: 50%;
  }

  .booking-container .booking-heading {
    text-align: center;
  }
}