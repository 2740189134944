@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

.consultation_info_container {
    display: flex;
    flex-wrap: wrap;
    margin: 20px;
}

.consultation_img_container {
    width: 100%;
    max-width: 370px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.consultation_img_container img {
    margin-top: 15vh;
    width: 100%;
    height: 50%;
    max-height: 600px;
    object-fit: cover;
}

.consultation_information_container {
    width: 100%;
    max-width: 700px;
    margin: auto;
    padding: 20px;
    /* margin-left: 20px !important; */
}

.consultation_form_container {
    margin: 30px 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    background: #f9f9f9;
}

.booking_form_container {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 10px;
    margin: 20px 0;
    background: radial-gradient(at center, #ffffff, #ddf1ff);
}

.booking_form_container .consultation_info_heading {
    margin: 0;
}

.consultation_info_heading {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    color: #023047;
    font-size: 2rem;
    margin: 20px 0;
}

.booking_info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
}

.booking_info .booking_sub_heading {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    color: #a9b1b9;
}

.booking_info .booking_info_details {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    color: #343a40;
    font-size: 1rem;
    text-align: right;
}

.booking_info_details .payment_failed {
    color: rgb(157, 14, 14);
    font-weight: 600;
}

.client_information_Container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.client_information_Container .client_info_card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 10px;
    background: radial-gradient(at center, #ffffff, #ddf1ff);
    border-radius: 10px;
    flex: 1 1 300px;
}

.info_sub_heading {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #023047;
}

@media (max-width: 1389px) {
    .consultation_img_container {
        align-items: center;
        justify-content: center;
        margin: auto;
    }

    .consultation_img_container img {
        margin-top: 10px;
        max-height: 500px;
    }
}

@media (max-width: 800px) {
    .consultation_info_container {
        flex-direction: column;
        margin: 10px;
    }

    .consultation_information_container {
        width: 100%;
        margin: 10px 0;
        align-items: center;
        justify-content: center;
    }

    .consultation_img_container {
        align-items: center;
        justify-content: center;
    }

    .consultation_img_container img {
        margin-top: 10px;
        max-height: 400px;
    }

    .booking_info .booking_info_details {
        font-size: 0.9rem;
    }
}