@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

.delete_ac_container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100vh;
  background-color: white;
  padding-top: 50px;
}

.deleteImg_container {
  width: 50%;
  height: auto;
}

.deleteAc_img {
  width: 100%;

}

.deleteAc_form_container {
  background-color: white;
  height: auto;
  width: 30%;
  border-radius: 10px;
  box-shadow: 0.2em 0.2em 0.5em rgba(0, 0, 0, 0.3);
  margin: 2em auto 30px auto;
  padding: 2em;
}

.deleteAc_heading {
  margin-top: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 600 !important;
  text-align: center;
  color: #4361ee;
  font-size: 33px !important;
}

.deleteAc_form_container .first_one {
  margin-top: 30px !important;
}

.deleteAc_form_container .input-group {
  position: relative;
  margin-top: 2em;
}

.deleteAc_form_container .second_one {
  margin-bottom: 15px !important;
}

.deleteAc_form_container .input {
  border: solid 1.5px #9e9e9e;
  border-radius: 1rem;
  border-top-right-radius: 1em !important;
  border-bottom-right-radius: 1em !important;
  background: none;
  padding: 1rem;
  font-size: 1rem;
  color: #212121;
  transition: border 150ms cubic-bezier(0.4, 0, 0.2, 1);
  width: 100%;
  background-color: white;
}

.deleteAc_form_container .user-label {
  position: absolute;
  left: 15px;
  color: #9e9e9e;
  pointer-events: none;
  transform: translateY(1rem);
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
  background-color: white;
  /* Ensure background is white */
  padding: 0 .2em;
  border-radius: 1rem;
  /* Match the label border-radius to the input */
}

.deleteAc_form_container .input:focus,
.deleteAc_form_container .input:valid {
  outline: none;
  border: 1.5px solid #1a73e8;
}

.deleteAc_form_container .input:focus~.user-label,
.deleteAc_form_container .input:valid~.user-label {
  transform: translateY(-50%) scale(0.8);
  background-color: white;
  padding: 0 .2em;
  color: #2196f3;
}

.deleteAc_form_container .button {
  background-color: #4361ee;
  width: 100%;
  height: 50px;
  margin-top: 30px !important;
  border-radius: 7px;
  border: 2px solid transparent;

  text-shadow: 1px 1px 1px #00000040;
  box-shadow: 8px 8px 20px 0px #23347259;

  padding: 10px 40px;
  line-height: 20px;
  cursor: pointer;
  transition: all 0.3s;
  color: white;
  font-size: 18px;
  font-weight: 500;
}

.deleteAc_form_container .button:hover {
  box-shadow: none;
  opacity: 80%;
}

.deleteAc_form_container .delete_ac_info {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  text-align: center;
  font-size: 15px;
  margin-top: 20px;
  margin-bottom: 0;
}

@media (max-width: 1024px) {
  .delete_ac_container {
    flex-direction: column;
  }

  .deleteAc_form_container {
    width: 90% !important;
    height: auto;
    margin-top: 20px;
  }

  .deleteImg_container {
    margin: auto;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 376px) {
  .delete_ac_container {
    margin-top: 0px;
    height: auto;
  }
}

@media (min-width: 376px) and (max-width: 767px) {
  .delete_ac_container {
    margin-top: 25px;
    padding-top: 0px;
    height: auto;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .delete_ac_container {
    margin-top: 15px;
    padding-top: 0px;
    height: auto;
  }
}

@media (min-width: 1025px) and (max-width: 1440px) {
  .delete_ac_container {
    margin-top: 30px;
  }
}

@media (min-width: 1441px) {
  .delete_ac_container {
    margin-top: 30px;
  }
}


@media (max-width: 850px){
  .delete_ac_container{
    width: 90%;
  }
}