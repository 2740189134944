[tabindex='-1']:focus {
    outline: 0 !important;
}

.profile-content hr {
    overflow: visible;
    box-sizing: content-box;
    height: 0;
}


.card-profile-image .initalAvatar {
    background-color: #f0f0f0;
    color: #333;
    text-align: center;
    width: 200px;
    height: 230px;
    line-height: 230px;
    font-size: 56px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
}



.profile-content h1,
.profile-content h3,
.profile-content h4,
.profile-content h5,
.profile-content h6 {
    margin-top: 0;
    margin-bottom: .5rem;
}

.profile-content p {
    margin-top: 0;
    margin-bottom: 1rem;
}

.profile-content address {
    font-style: normal;
    line-height: inherit;
    margin-bottom: 1rem;
}

.profile-content ul {
    margin-top: 0;
    margin-bottom: 1rem;
}

.profile-content ul ul {
    margin-bottom: 0;
}

.profile-content dfn {
    font-style: italic;
}

.profile-content strong {
    font-weight: bolder;
}

.profile-content a {
    text-decoration: none;
    color: #5e72e4;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
}

.profile-content a:hover {
    text-decoration: none;
    color: #233dd2;
}

.profile-content a:not([href]):not([tabindex]) {
    text-decoration: none;
    color: inherit;
}

.profile-content a:not([href]):not([tabindex]):hover,
.profile-content a:not([href]):not([tabindex]):focus {
    text-decoration: none;
    color: inherit;
}

.profile-content a:not([href]):not([tabindex]):focus {
    outline: 0;
}

.profile-content code {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
    font-size: 1em;
}

.profile-content img {
    vertical-align: middle;
    border-style: none;
}

.profile-content caption {
    padding-top: 1rem;
    padding-bottom: 1rem;
    caption-side: bottom;
    text-align: left;
    color: #8898aa;
}

.profile-content label {
    display: inline-block;
    margin-bottom: .5rem;
}

.profile-content button {
    border-radius: 0;
}

.profile-content button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
}

.profile-content input,
.profile-content button,
.profile-content textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    margin: 0;
}

.profile-content button,
.profile-content input {
    overflow: visible;
}

.profile-content button {
    text-transform: none;
}

.profile-content button,
html [type='button'],
[type='reset'],
[type='submit'] {
    -webkit-appearance: button;
}

.profile-content button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
    padding: 0;
    border-style: none;
}

.profile-content input[type='radio'],
.profile-content input[type='checkbox'] {
    box-sizing: border-box;
    padding: 0;
}

.profile-content input[type='date'],
.profile-content input[type='time'],
.profile-content input[type='datetime-local'],
.profile-content input[type='month'] {
    -webkit-appearance: listbox;
}

.profile-content textarea {
    overflow: auto;
    resize: vertical;
}

.profile-content legend {
    font-size: 1.5rem;
    line-height: inherit;
    display: block;
    width: 100%;
    max-width: 100%;
    margin-bottom: .5rem;
    padding: 0;
    white-space: normal;
    color: inherit;
}

.profile-content progress {
    vertical-align: baseline;
}

.profile-content [type='number']::-webkit-inner-spin-button,
.profile-content [type='number']::-webkit-outer-spin-button {
    height: auto;
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
}

[hidden] {
    display: none !important;
}

.profile-content h1,
.profile-content h3,
.profile-content h4,
.profile-content h5,
.profile-content h6,
.profile-content .h1,
.profile-content .h3,
.profile-content .h4,
.profile-content .h5,
.profile-content .h6 {
    font-family: inherit;
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: .5rem;
    color: #32325d;
}

.profile-content h1,
.profile-content .h1 {
    font-size: 1.625rem;
}

.profile-content h3,
.profile-content .h3 {
    font-size: 1.0625rem;
}

.profile-content h4,
.profile-content .h4 {
    font-size: .9375rem;
}

.profile-content h5,
.profile-content .h5 {
    font-size: .8125rem;
}

.profile-content h6,
.profile-content .h6 {
    font-size: .625rem;
}

.profile-content .display-2 {
    font-size: 2.75rem;
    font-weight: 600;
    line-height: 1.5;
}

.profile-content hr {
    margin-top: 2rem;
    margin-bottom: 2rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, .1);
}

.profile-content code {
    font-size: 87.5%;
    word-break: break-word;
    color: #f3a4b5;
}

.profile-content a>code {
    color: inherit;
}

.profile-content .container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 15px;
    padding-left: 15px;
}

@media (min-width: 576px) {
    .profile-content .container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .profile-content .container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .profile-content .container {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .profile-content .container {
        max-width: 1140px;
    }
}

.profile-content .container-fluid {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 15px;
    padding-left: 15px;
}

.profile-content .row {
    display: flex;
    margin-right: -15px;
    margin-left: -15px;
    flex-wrap: wrap;
}

.profile-content .col-4,
.profile-content .col-8,
.profile-content .col,
.profile-content .col-md-10,
.profile-content .col-md-12,
.profile-content .col-lg-4,
.profile-content .col-lg-6,
.profile-content .col-lg-7,
.profile-content .col-xl-4,
.profile-content .col-xl-6,
.profile-content .col-xl-8 {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-top: 10px;
    padding-left: 15px;
    margin-top: 10px;
}

.profile-content .col {
    max-width: 100%;
    flex-basis: 0;
    flex-grow: 1;
}

.profile-content .col-4 {
    max-width: 33.33333%;
    flex: 0 0 33.33333%;
}

.profile-content .col-8 {
    max-width: 66.66667%;
    flex: 0 0 66.66667%;
}

@media (min-width: 768px) {

    .profile-content .col-md-10 {
        max-width: 83.33333%;
        flex: 0 0 83.33333%;
    }

    .profile-content .col-md-12 {
        max-width: 100%;
        flex: 0 0 100%;
    }
}

@media (min-width: 992px) {

    .profile-content .col-lg-3 {
        max-width: 25%;
        flex: 0 0 25%;
    }

    .profile-content.col-lg-4 {
        max-width: 33.33333%;
        flex: 0 0 33.33333%;
    }

    .profile-content .col-lg-6 {
        max-width: 50%;
        flex: 0 0 50%;
    }

    .profile-content .col-lg-7 {
        max-width: 58.33333%;
        flex: 0 0 58.33333%;
    }

    .profile-content .order-lg-2 {
        order: 2;
    }
}

@media (min-width: 1200px) {

    .profile-content .col-xl-4 {
        max-width: 33.33333%;
        flex: 0 0 33.33333%;
    }

    .profile-content .col-xl-6 {
        max-width: 50%;
        flex: 0 0 50%;
    }

    .profile-content .col-xl-8 {
        max-width: 66.66667%;
        flex: 0 0 66.66667%;
    }

    .profile-content .order-xl-1 {
        order: 1;
    }

    .profile-content .order-xl-2 {
        order: 2;
    }
}

.profile-content .form-control {
    font-size: 1rem;
    line-height: 1.5;
    display: block;
    width: 100%;
    height: calc(2.75rem + 2px);
    padding: .625rem .75rem;
    transition: all .2s cubic-bezier(.68, -.55, .265, 1.55);
    color: #8898aa;
    border: 1px solid #cad1d7;
    border-radius: .375rem;
    background-color: #fff;
    background-clip: padding-box;
    box-shadow: none;
}

@media screen and (prefers-reduced-motion: reduce) {
    .profile-content .form-control {
        transition: none;
    }
}

.profile-content .form-control::-ms-expand {
    border: 0;
    background-color: transparent;
}

.profile-contentl .form-control:focus {
    color: #8898aa;
    border-color: rgba(50, 151, 211, .25);
    outline: 0;
    background-color: #fff;
    box-shadow: none, none;
}

.profile-content .form-control:-ms-input-placeholder {
    opacity: 1;
    color: #adb5bd;
}

.profile-content .form-control::-ms-input-placeholder {
    opacity: 1;
    color: #adb5bd;
}

.profile-content .form-control::placeholder {
    opacity: 1;
    color: #adb5bd;
}

.profile-content .form-control:disabled,
.form-control[readonly] {
    opacity: 1;
    background-color: #e9ecef;
}

.profile-content textarea.form-control {
    height: auto;
}

.profile-content .profile-content .form-group {
    margin-bottom: 1.5rem;
}

.profile-content .form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
}

@media (min-width: 576px) {
    .profile-content .form-inline label {
        display: flex;
        margin-bottom: 0;
        align-items: center;
        justify-content: center;
    }

    .profile-content .form-inline .form-group {
        display: flex;
        margin-bottom: 0;
        flex: 0 0 auto;
        flex-flow: row wrap;
        align-items: center;
    }

    .profile-content .form-inline .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle;
    }

    .profile-content .form-inline .input-group {
        width: auto;
    }
}

.profile-content .btn {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5;
    display: inline-block;
    padding: .625rem 1.25rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    border: 1px solid transparent;
    border-radius: .375rem;
}

@media screen and (prefers-reduced-motion: reduce) {
    .profile-content .btn {
        transition: none;
    }
}

.profile-content .btn:hover,
.profile-content .btn:focus {
    text-decoration: none;
}

.profile-content .btn:focus {
    outline: 0;
    box-shadow: 0 7px 14px rgba(50, 50, 93, .1), 0 3px 6px rgba(0, 0, 0, .08);
}

.profile-content .btn:disabled {
    opacity: .65;
    box-shadow: none;
}

.profile-content .btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.profile-content .btn:not(:disabled):not(.disabled):active {
    box-shadow: none;
}

.profile-content .btn:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 7px 14px rgba(50, 50, 93, .1), 0 3px 6px rgba(0, 0, 0, .08), none;
}

.profile-content .btn-primary {
    color: #fff;
    border-color: #5e72e4;
    background-color: #5e72e4;
    box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
}

.profile-content .btn-primary:hover {
    color: #fff;
    border-color: #5e72e4;
    background-color: #5e72e4;
}

.profile-content .btn-primary:focus {
    box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08), 0 0 0 0 rgba(94, 114, 228, .5);
}

.profile-content .btn-primary:disabled {
    color: #fff;
    border-color: #5e72e4;
    background-color: #5e72e4;
}

.profile-content .btn-primary:not(:disabled):not(.disabled):active {
    color: #fff;
    border-color: #5e72e4;
    background-color: #324cdd;
}

.profile-content .btn-primary:not(:disabled):not(.disabled):active:focus {
    box-shadow: none, 0 0 0 0 rgba(94, 114, 228, .5);
}

.profile-content .btn-info {
    color: #fff;
    border-color: #11cdef;
    background-color: #11cdef;
    box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
}

.profile-content .btn-info:hover {
    color: #fff;
    border-color: #11cdef;
    background-color: #11cdef;
}

.profile-content .btn-info:focus {
    box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08), 0 0 0 0 rgba(17, 205, 239, .5);
}

.profile-content .btn-info:disabled {
    color: #fff;
    border-color: #11cdef;
    background-color: #11cdef;
}

.profile-content .btn-info:not(:disabled):not(.disabled):active {
    color: #fff;
    border-color: #11cdef;
    background-color: #0da5c0;
}

.profile-content .btn-info:not(:disabled):not(.disabled):active:focus {
    box-shadow: none, 0 0 0 0 rgba(17, 205, 239, .5);
}

.profile-content .btn-default {
    color: #fff;
    border-color: #172b4d;
    background-color: #172b4d;
    box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
}

.profile-content .btn-default:hover {
    color: #fff;
    border-color: #172b4d;
    background-color: #172b4d;
}

.profile-content .btn-default:focus {
    box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08), 0 0 0 0 rgba(23, 43, 77, .5);
}

.profile-content .btn-default:disabled {
    color: #fff;
    border-color: #172b4d;
    background-color: #172b4d;
}

.profile-content .btn-default:not(:disabled):not(.disabled):active {
    color: #fff;
    border-color: #172b4d;
    background-color: #0b1526;
}

.profile-content .btn-default:not(:disabled):not(.disabled):active:focus {
    box-shadow: none, 0 0 0 0 rgba(23, 43, 77, .5);
}

.profile-content .btn-sm {
    font-size: .875rem;
    line-height: 1.5;
    padding: .25rem .5rem;
    border-radius: .375rem;
}

.profile-content .dropdown {
    position: relative;
}

.profile-content .dropdown-menu {
    font-size: 1rem;
    position: absolute;
    z-index: 1000;
    top: 100%;
    left: 0;
    display: none;
    float: left;
    min-width: 10rem;
    margin: .125rem 0 0;
    padding: .5rem 0;
    list-style: none;
    text-align: left;
    color: #525f7f;
    border: 0 solid rgba(0, 0, 0, .15);
    border-radius: .4375rem;
    background-color: #fff;
    background-clip: padding-box;
    box-shadow: 0 50px 100px rgba(50, 50, 93, .1), 0 15px 35px rgba(50, 50, 93, .15), 0 5px 15px rgba(0, 0, 0, .1);
}

.profile-content .dropdown-menu.show {
    display: block;
    opacity: 1;
}

.profile-content .dropdown-menu-right {
    right: 0;
    left: auto;
}

.profile-content .dropdown-menu[x-placement^='top'],
.profile-content .dropdown-menu[x-placement^='right'],
.profile-content .dropdown-menu[x-placement^='bottom'],
.profile-content .dropdown-menu[x-placement^='left'] {
    right: auto;
    bottom: auto;
}

.profile-content .dropdown-divider {
    overflow: hidden;
    height: 0;
    margin: .5rem 0;
    border-top: 1px solid #e9ecef;
}

.profile-content .dropdown-item {
    font-weight: 400;
    display: block;
    clear: both;
    width: 100%;
    padding: .25rem 1.5rem;
    text-align: inherit;
    white-space: nowrap;
    color: #212529;
    border: 0;
    background-color: transparent;
}

.profile-content .dropdown-item:hover,
.profile-content .dropdown-item:focus {
    text-decoration: none;
    color: #16181b;
    background-color: #f6f9fc;
}

.profile-content .dropdown-item:active {
    text-decoration: none;
    color: #fff;
    background-color: #5e72e4;
}

.profile-content .dropdown-item:disabled {
    color: #8898aa;
    background-color: transparent;
}

.profile-content .dropdown-header {
    font-size: .875rem;
    display: block;
    margin-bottom: 0;
    padding: .5rem 1.5rem;
    white-space: nowrap;
    color: #8898aa;
}

.profile-content .input-group {
    position: relative;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    align-items: stretch;
}

.profile-content .input-group>.form-control {
    position: relative;
    width: 1%;
    margin-bottom: 0;
    flex: 1 1 auto;
}

.profile-content .input-group>.form-control+.form-control {
    margin-left: -1px;
}

.profile-content .input-group>.form-control:focus {
    z-index: 3;
}

.profile-content .input-group>.form-control:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.profile-content .input-group>.form-control:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.profile-content .input-group-prepend {
    display: flex;
}

.profile-content .input-group-prepend .btn {
    position: relative;
    z-index: 2;
}

.profile-content .input-group-prepend .btn+.btn,
.profile-content .input-group-prepend .btn+.input-group-text,
.profile-content .input-group-prepend .input-group-text+.input-group-text,
.profile-content .input-group-prepend .input-group-text+.btn {
    margin-left: -1px;
}

.profile-content .input-group-prepend {
    margin-right: -1px;
}

.profile-content .input-group-text {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    display: flex;
    margin-bottom: 0;
    padding: .625rem .75rem;
    text-align: center;
    white-space: nowrap;
    color: #adb5bd;
    border: 1px solid #cad1d7;
    border-radius: .375rem;
    background-color: #fff;
    align-items: center;
}

.profile-content .input-group-text input[type='radio'],
.profile-content .input-group-text input[type='checkbox'] {
    margin-top: 0;
}

.profile-content .input-group>.input-group-prepend>.btn,
.profile-content .input-group>.input-group-prepend>.input-group-text {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.profile-content .input-group>.input-group-prepend:not(:first-child)>.btn,
.profile-content .input-group>.input-group-prepend:not(:first-child)>.input-group-text,
.profile-content .input-group>.input-group-prepend:first-child>.btn:not(:first-child),
.profile-content .input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.profile-content .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    border: 1px solid rgba(0, 0, 0, .05);
    border-radius: .375rem;
    background-color: #fff;
    background-clip: border-box;
}

.profile-content .card>hr {
    margin-right: 0;
    margin-left: 0;
}

.profile-content .card-body {
    padding: 1.5rem;
    flex: 1 1 auto;
}

.profile-content .card-header {
    margin-bottom: 0;
    padding: 1.25rem 1.5rem;
    border-bottom: 1px solid rgba(0, 0, 0, .05);
    background-color: #fff;
}

.profile-content .card-header:first-child {
    border-radius: calc(.375rem - 1px) calc(.375rem - 1px) 0 0;
}

@keyframes progress-bar-stripes {
    from {
        background-position: 1rem 0;
    }

    to {
        background-position: 0 0;
    }
}

.profile-content .progress {
    font-size: .75rem;
    display: flex;
    overflow: hidden;
    height: 1rem;
    border-radius: .375rem;
    background-color: #e9ecef;
    box-shadow: inset 0 .1rem .1rem rgba(0, 0, 0, .1);
}

.profile-content .media {
    display: flex;
    align-items: flex-start;
}

.profile-content .media-body {
    flex: 1 1;
}

.profile-content .bg-secondary {
    background-color: #f7fafc !important;
}

.profile-content a.bg-secondary:hover,
.profile-content a.bg-secondary:focus,
.profile-content button.bg-secondary:hover,
.profile-content button.bg-secondary:focus {
    background-color: #d2e3ee !important;
}

.profile-content .bg-default {
    background-color: #172b4d !important;
}

.profile-content a.bg-default:hover,
.profile-content a.bg-default:focus,
.profile-content button.bg-default:hover,
.profile-content button.bg-default:focus {
    background-color: #0b1526 !important;
}

.profile-content .bg-white {
    background-color: #fff !important;
}

.profile-content a.bg-white:hover,
.profile-content a.bg-white:focus,
.profile-content button.bg-white:hover,
.profile-content button.bg-white:focus {
    background-color: #e6e6e6 !important;
}

.profile-content .bg-white {
    background-color: #fff !important;
}

.profile-content .border-0 {
    border: 0 !important;
}

.profile-content .rounded-circle {
    border-radius: 50% !important;
}

.profile-content .d-none {
    display: none !important;
}

.profile-content .d-flex {
    display: flex !important;
}

@media (min-width: 768px) {

    .profile-content .d-md-flex {
        display: flex !important;
    }
}

@media (min-width: 992px) {

    .profile-content .d-lg-inline-block {
        display: inline-block !important;
    }

    .profile-content .d-lg-block {
        display: block !important;
    }
}

.profile-content .justify-content-center {
    justify-content: center !important;
}

.profile-content .justify-content-between {
    justify-content: space-between !important;
}

.profile-content .align-items-center {
    align-items: center !important;
}

@media (min-width: 1200px) {

    .profile-content .justify-content-xl-between {
        justify-content: space-between !important;
    }
}

.profile-content .float-right {
    float: right !important;
}

.profile-content .shadow,
.profile-content .card-profile-image img {
    box-shadow: 0 0 2rem 0 rgba(136, 152, 170, .15) !important;
}

.profile-content .m-0 {
    margin: 0 !important;
}

.profile-content .mt-0 {
    margin-top: 0 !important;
}

.profile-content .mb-0 {
    margin-bottom: 0 !important;
}

.profile-content .mr-2 {
    margin-right: .5rem !important;
}

.profile-content .ml-2 {
    margin-left: .5rem !important;
}

.profile-content .mr-3 {
    margin-right: 1rem !important;
}

.profile-content .mt-4,
.profile-content .my-4 {
    margin-top: 1.5rem !important;
}

.profile-content .mr-4 {
    margin-right: 1.5rem !important;
}

.profile-content .mb-4,
.profile-content .my-4 {
    margin-bottom: 1.5rem !important;
}

.profile-content .mb-5 {
    margin-bottom: 3rem !important;
}

.profile-content .mt--7 {
    margin-top: -6rem !important;
}

.profile-content .pt-0 {
    padding-top: 0 !important;
}

.profile-content .pr-0 {
    padding-right: 0 !important;
}

.profile-content .pb-0 {
    padding-bottom: 0 !important;
}

.profile-content .pt-5 {
    padding-top: 3rem !important;
}

.profile-content .pt-8 {
    padding-top: 6rem !important;
}

.profile-content .pb-8 {
    padding-bottom: 8rem !important;
}

.profile-content .m-auto {
    margin: auto !important;
}

@media (min-width: 1024px) {
    .profile-content .mt-md-5 {
        margin-top: 5rem !important;
    }

    .profile-content .pt-md-4 {
        padding-top: 1.5rem !important;
    }

    .profile-content .pb-md-4 {
        padding-bottom: 1.5rem !important;
    }
}

@media (max-width: 767px) {
    .profile-content .mt-md-5 {
        margin-top: 3rem !important;
    }

    .profile-content .pt-md-4 {
        padding-top: 1.5rem !important;
    }

    .profile-content .pb-md-4 {
        padding-bottom: 1.5rem !important;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .profile-content .mt-md-5 {
        margin-top: 5rem !important;
    }

    .profile-content .pt-md-4 {
        padding-top: 1.5rem !important;
    }

    .profile-content .pb-md-4 {
        padding-bottom: 1.5rem !important;
    }
}

@media (min-width: 992px) {
    .profile-content .pl-lg-4 {
        padding-left: 1.5rem !important;
    }

    .profile-content .pt-lg-8 {
        padding-top: 8rem !important;
    }

    .profile-content .ml-lg-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 1200px) {
    .profile-content .mb-xl-0 {
        margin-bottom: 0 !important;
    }
}

.profile-content .text-right {
    text-align: right !important;
}

.profile-content .text-center {
    text-align: center !important;
}

.profile-content .text-uppercase {
    text-transform: uppercase !important;
}

.profile-content .font-weight-light {
    font-weight: 300 !important;
}

.profile-content .font-weight-bold {
    font-weight: 600 !important;
}

.profile-content .text-white {
    color: #fff !important;
}

.profile-content .text-white {
    color: #fff !important;
}

.profile-content a.text-white:hover,
.profile-content a.text-white:focus {
    color: #e6e6e6 !important;
}

.profile-content .text-muted {
    color: #8898aa !important;
}

.profile-content figcaption,
.profile-content main {
    display: block;
}

.profile-content main {
    overflow: hidden;
}

.profile-content .bg-white {
    background-color: #fff !important;
}

.profile-content a.bg-white:hover,
.profile-content a.bg-white:focus,
.profile-content button.bg-white:hover,
.profile-content button.bg-white:focus {
    background-color: #e6e6e6 !important;
}

.profile-content .bg-gradient-default {
    background: linear-gradient(87deg, #172b4d 0, #1a174d 100%) !important;
}

.profile-content .bg-gradient-default {
    background: linear-gradient(87deg, #172b4d 0, #1a174d 100%) !important;
}

@keyframes floating-lg {
    0% {
        transform: translateY(0px);
    }

    50% {
        transform: translateY(15px);
    }

    100% {
        transform: translateY(0px);
    }
}

@keyframes floating {
    0% {
        transform: translateY(0px);
    }

    50% {
        transform: translateY(10px);
    }

    100% {
        transform: translateY(0px);
    }
}

@keyframes floating-sm {
    0% {
        transform: translateY(0px);
    }

    50% {
        transform: translateY(5px);
    }

    100% {
        transform: translateY(0px);
    }
}

.profile-content .opacity-8 {
    opacity: .8 !important;
}

.profile-content .opacity-8 {
    opacity: .9 !important;
}

.profile-content .center {
    left: 50%;
    transform: translateX(-50%);
}

.profile-content [class*='shadow'] {
    transition: all .15s ease;
}

.profile-content .font-weight-300 {
    font-weight: 300 !important;
}

.profile-content .text-sm {
    font-size: .875rem !important;
}

.profile-content .text-white {
    color: #fff !important;
}

.profile-content a.text-white:hover,
.profile-content a.text-white:focus {
    color: #e6e6e6 !important;
}

.profile-content .avatar {
    font-size: 1rem;
    display: inline-flex;
    width: 48px;
    height: 48px;
    color: #fff;
    border-radius: 50%;
    background-color: #adb5bd;
    align-items: center;
    justify-content: center;
}

.profile-content .avatar img {
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.profile-content .avatar-sm {
    font-size: .875rem;
    width: 36px;
    height: 36px;
}

.profile-content .btn {
    font-size: .875rem;
    position: relative;
    transition: all .15s ease;
    letter-spacing: .025em;
    text-transform: none;
    will-change: transform;
}

.profile-content .btn:hover {
    transform: translateY(-1px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, .1), 0 3px 6px rgba(0, 0, 0, .08);
}

.profile-content .btn:not(:last-child) {
    margin-right: .5rem;
}

.profile-content .btn i:not(:first-child) {
    margin-left: .5rem;
}

.profile-content .btn i:not(:last-child) {
    margin-right: .5rem;
}

.profile-content .input-group .btn {
    margin-right: 0;
    transform: translateY(0);
}

.profile-content .btn-sm {
    font-size: .75rem;
}

.profile-content [class*='btn-outline-'] {
    border-width: 1px;
}

.profile-content .card-profile-image {
    position: relative;
}

.profile-content .card-profile-image img {
    position: absolute;
    left: 50%;
    max-width: 200px;
    transition: all .15s ease;
    transform: translate(-50%, -30%);
    border-radius: .375rem;
}

@media (max-width: 767px) {
    .profile-content .card-profile-image img {
        max-width: 170px;
    }
}

.profile-content .card-profile-image img:hover {
    transform: translate(-50%, -33%);
}

.profile-content .card-profile-stats {
    padding: 1rem 0;
}

.profile-content .card-profile-stats>div {
    margin-right: 1rem;
    padding: .875rem;
    text-align: center;
}

.profile-content .card-profile-stats>div:last-child {
    margin-right: 0;
}

.profile-content .card-profile-stats>div .heading {
    font-size: 1.1rem;
    font-weight: bold;
    display: block;
}

.profile-content .card-profile-stats>div .description {
    font-size: .875rem;
    color: #adb5bd;
}

.profile-content .profile-content {
    position: relative;
}

@media (min-width: 768px) {
    .profile-content .container-fluid {
        padding-right: 39px !important;
        padding-left: 39px !important;
    }
}

.profile-content .dropdown {
    display: inline-block;
}

.profile-content .dropdown-menu {
    min-width: 12rem;
}

.profile-content .dropdown-menu .dropdown-item {
    font-size: .875rem;
    padding: .5rem 1rem;
}

.profile-content .dropdown-menu .dropdown-item>i {
    font-size: 1rem;
    margin-right: 1rem;
    vertical-align: -17%;
}

.profile-content .dropdown-header {
    font-size: .625rem;
    font-weight: 700;
    padding-right: 1rem;
    padding-left: 1rem;
    text-transform: uppercase;
    color: #f6f9fc;
}

.profile-content .dropdown-menu a.media>div:first-child {
    line-height: 1;
}

.profile-content .dropdown-menu a.media p {
    color: #8898aa;
}

.profile-content .dropdown-menu a.media:hover .heading,
.profile-content .dropdown-menu a.media:hover p {
    color: #172b4d !important;
}

.profile-content .form-control-label {
    font-size: .875rem;
    font-weight: 600;
    color: #525f7f;
}

.profile-content .form-control {
    font-size: .875rem;
}

.profile-content .form-control:focus:-ms-input-placeholder {
    color: #adb5bd;
}

.profile-content .form-control:focus::-ms-input-placeholder {
    color: #adb5bd;
}

.profile-content .form-control:focus::placeholder {
    color: #adb5bd;
}

.profile-content textarea[resize='none'] {
    resize: none !important;
}

.profile-content textarea[resize='both'] {
    resize: both !important;
}

.profile-content textarea[resize='vertical'] {
    resize: vertical !important;
}

.profile-content textarea[resize='horizontal'] {
    resize: horizontal !important;
}

.profile-content .form-control-alternative {
    transition: box-shadow .15s ease;
    border: 0;
    box-shadow: 0 1px 3px rgba(50, 50, 93, .15), 0 1px 0 rgba(0, 0, 0, .02);
}

.profile-content .form-control-alternative:focus {
    box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
}

.profile-content .input-group {
    transition: all .15s ease;
    border-radius: .375rem;
    box-shadow: none;
}

.profile-content .input-group .form-control {
    box-shadow: none;
}

.profile-content .input-group .form-control:not(:first-child) {
    padding-left: 0;
    border-left: 0;
}

.profile-content .input-group .form-control:not(:last-child) {
    padding-right: 0;
    border-right: 0;
}

.profile-content .input-group .form-control:focus {
    box-shadow: none;
}

.profile-content .input-group-text {
    transition: all .2s cubic-bezier(.68, -.55, .265, 1.55);
}

.profile-content .input-group-alternative {
    transition: box-shadow .15s ease;
    border: 0;
    box-shadow: 0 1px 3px rgba(50, 50, 93, .15), 0 1px 0 rgba(0, 0, 0, .02);
}

.profile-content .input-group-alternative .form-control,
.profile-content .input-group-alternative .input-group-text {
    border: 0;
    box-shadow: none;
}

.profile-content .focused .input-group-alternative {
    box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08) !important;
}

.profile-content .focused .input-group {
    box-shadow: none;
}

.profile-content .focused .input-group-text {
    color: #8898aa;
    border-color: rgba(50, 151, 211, .25);
    background-color: #fff;
}

.profile-content .focused .form-control {
    border-color: rgba(50, 151, 211, .25);
}

.profile-content .header {
    position: relative;
    z-index: -100;
}

.profile-content .input-group {
    transition: all .15s ease;
    border-radius: .375rem;
    box-shadow: none;
}

.profile-content .input-group .form-control {
    box-shadow: none;
}

.profile-content .input-group .form-control:not(:first-child) {
    padding-left: 0;
    border-left: 0;
}

.profile-content .input-group .form-control:not(:last-child) {
    padding-right: 0;
    border-right: 0;
}

.profile-content .input-group .form-control:focus {
    box-shadow: none;
}

.profile-content .input-group-text {
    transition: all .2s cubic-bezier(.68, -.55, .265, 1.55);
}

.profile-content .input-group-alternative {
    transition: box-shadow .15s ease;
    border: 0;
    box-shadow: 0 1px 3px rgba(50, 50, 93, .15), 0 1px 0 rgba(0, 0, 0, .02);
}

.profile-content .input-group-alternative .form-control,
.profile-content .input-group-alternative .input-group-text {
    border: 0;
    box-shadow: none;
}

.profile-content .focused .input-group-alternative {
    box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08) !important;
}

.profile-content .focused .input-group {
    box-shadow: none;
}

.profile-content .focused .input-group-text {
    color: #8898aa;
    border-color: rgba(50, 151, 211, .25);
    background-color: #fff;
}

.profile-content .focused .form-control {
    border-color: rgba(50, 151, 211, .25);
}

.profile-content .mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all .15s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
    .profile-content .mask {
        transition: none;
    }
}

.profile-content .progress {
    overflow: hidden;
    height: 8px;
    margin-bottom: 1rem;
    border-radius: .25rem;
    background-color: #e9ecef;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
}

.profile-content p {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.7;
}

.profile-content .description {
    font-size: .875rem;
}

.profile-content .heading {
    font-size: .95rem;
    font-weight: 600;
    letter-spacing: .025em;
    text-transform: uppercase;
}

.profile-content .heading-small {
    font-size: .75rem;
    padding-top: .25rem;
    padding-bottom: .25rem;
    letter-spacing: .04em;
    text-transform: uppercase;
}

.profile-content .display-2 span {
    font-weight: 300;
    display: block;
}

@media (max-width: 768px) {
    .profile-content .btn {
        margin-bottom: 10px;
    }
}