/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
.footer {
    --font-primary: "Montserrat", sans-serif;
    --color-box-background: #eaeaea;
    --color-default: #212529;
    --color-default-rgb: 33, 37, 41;
    --color-background: #ffffff;
    --color-background-rgb: 255, 255, 255;
    --color-primary: #e84545;
    --color-primary-rgb: 232, 69, 69;
    --color-secondary: #32353a;
    --color-secondary-rgb: 50, 53, 58;
    --color-box-background-rgb: 255, 255, 255;
    --color-inverse: #ffffff;
    --color-inverse-rgb: 255, 255, 255;
    color: var(--color-default);
    background-color: var(--color-background);
    font-size: 14px;
    margin-bottom: 20px;
}

.footer .footer-about {
    padding-right: 20px;
}

.footer .footer-about .logo {
    line-height: 0;
    margin-bottom: 25px;
}

.footer .footer-about .logo img {
    max-height: 40px;
    margin-right: 6px;
}

.footer .footer-about .logo span {
    color: var(--color-secondary);
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 1px;
    font-family: var(--font-primary);
}

.footer .footer-about .logo span .logo-dot {
    color: var(--color-primary);
}

.footer .footer-about p {
    font-size: 14px;
    font-family: var(--font-primary);
}

.footer .social-links a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid rgba(var(--color-default-rgb), 0.5);
    font-size: 16px;
    color: rgba(var(--color-default-rgb), 0.5);
    margin-right: 10px;
    transition: 0.3s;
}

.footer .social-links a:hover {
    color: var(--color-primary);
    border-color: var(--color-primary);
}

.footer h4 {
    color: var(--color-secondary);
    font-size: 16px;
    font-weight: bold;
    position: relative;
    padding-bottom: 12px;
}

.footer .footer-links {
    margin-bottom: 30px;
}

.footer .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.footer .footer-links ul i {
    padding-right: 2px;
    font-size: 12px;
    line-height: 0;
}

.footer .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
}

.footer .footer-links ul li:first-child {
    padding-top: 0;
}

.footer .footer-links ul a {
    color: rgba(var(--color-default-rgb), 0.8);
    display: inline-block;
    line-height: 1;
}

.footer .footer-links ul a:hover {
    color: var(--color-primary);
    transition: 0.1s;
    transform: scale(1.02);
    font-weight: 500;
}

.footer .footer-contact p {
    margin-bottom: 5px;
}

.footer .copyright {
    padding-top: 25px;
    padding-bottom: 25px;
    background-color: var(--color-box-background);
}

.footer .copyright p {
    margin-bottom: 0;
}

.footer .credits {
    margin-top: 6px;
    font-size: 13px;
}

.container-footer {
    padding: 0px 70px 0px;
    max-width: 1500px;
    margin: 100px auto 0;
    align-items: center;
}

.footer-about {
    margin-bottom: 15px;
}

.footer-about .logo {
    font-size: 24px;
    color: #001973;
}

.footer-about p {
    text-align: justify;
    color: #555;
    margin-top: 15px;
}

.social-links a {
    font-size: 18px;
    margin-right: 15px;
    color: #555;
    transition: color 0.3s ease-in-out;
}

.social-links a:hover {
    color: #001973;
}

.footer-links h4 {
    font-size: 18px;
    color: #001973;
    margin-bottom: 20px;
}

.footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.footer-links li {
    margin-bottom: 8px;
}

.footer-links a {
    color: #555;
    text-decoration: none;
    transition: color 0.3s ease-in-out;
}

.footer-links a:hover {
    color: #001973;
}

.footer-contact h4 {
    font-size: 18px;
    color: #001973;
    margin-bottom: 14px;
}

.footer-contact p {
    color: #555;
    margin-bottom: 8px;
}

.footer-contact strong {
    color: #001973;
}

.footer-contact span {
    color: #555;
}

.footer .footer-about {
    padding-right: 20px;
}

@media (min-width: 576px) {
    .footer .footer-about {
        padding-right: 40px;
    }
}

@media (min-width: 768px) {
    .container-footer {
        padding: 0px 70px 0px;
    }

    .footer .footer-about {
        padding-right: 60px;
    }
}

@media (min-width: 992px) {
    .footer .footer-about {
        padding-right: 60px;
    }
}

@media (min-width: 1200px) {
    .footer .footer-about {
        padding: 0 60px;
    }
}