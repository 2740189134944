.home-therapist-cards {
  background-color: #e6f2ff;
  padding: 20px;
  font-family: Arial, sans-serif;
  padding-bottom: 10px;
  padding-top: 50px;
  margin-top: 20px;
}

.home-peers-cards {
  background-color: #e6f2ff;
  padding: 20px;
  font-family: Arial, sans-serif;
  padding-top: 30px;
  padding-bottom: 50px;
}

.therapist-card-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
}

.therapist-card-row {
  display: flex;
  gap: 20px;
  overflow-x: hidden;
}

.therapist-card {
  background-color: white;
  border-radius: 15px;
  padding: 20px;
  width: 300px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* Ensures items are spaced evenly */
  position: relative;
  /* For absolute positioning */
  height: auto !important;
}

.therapist-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 15px;
}

.therapist-name {
  margin: 0;
  font-size: 1.3em;
  font-weight: bold;
}

.therapist-username {
  color: #666;
  margin: 5px 0px 0px;
  font-size: 0.85em;
  font-weight: 600;
}

.therapist-details {
  color: #666;
  margin: 0px 0 15px;
  font-size: 0.9em;
}

.therapist-bio {
  font-size: 0.9em;
  margin-bottom: 20px;
  color: #333;
  line-height: 1.4;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.therapist-stats {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  font-size: 0.9em;
}

.therapist-stats div {
  display: flex;
  align-items: center;
}

.therapist-stats strong {
  margin-right: 5px;
}

.view-profile-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 0.9em;
  width: 100%;
  transition: background-color 0.3s;
  margin-top: auto;
}

.view-profile-btn:hover {
  background-color: #0051ff;
}

.nav-btn {
  background: white;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
  color: #007bff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s;
}

.nav-btn:hover {
  background-color: #f0f0f0;
}

.nav-btn:disabled {
  color: #ccc;
  background-color: #f0f0f0;
  cursor: not-allowed;
}

.prev {
  margin-right: 10px;
}

.next {
  margin-left: 10px;
}

.user_card_flex {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.user_details_cont {
  margin-left: 20px;

}

.therap_price {
  font-size: 20px !important;
}

.therap_profile_heading {
  font-size: 35px;
  margin-bottom: 20px;
  font-weight: 600;
  text-align: center;
  color: #001d3d;
}

.view_all_cont {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

/* From Uiverse.io by satyamchaudharydev */
.view-all-btn {
  --primary-color: #transparent;
  --secondary-color: #fff;
  --hover-color: #0051ff;
  --arrow-width: 10px;
  --arrow-stroke: 2px;
  box-sizing: border-box;
  border: 2px solid #007bff;
  border-radius: 40px;
  color: #007bff;
  padding: 0.7em 1.5em;
  background: var(--primary-color);
  display: flex;
  transition: 0.2s background;
  align-items: center;
  justify-content: center;
  gap: 0.6em;
  font-weight: bold;
}

.view-all-btn.arrow-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.view-all-btn .arrow {
  margin-top: 1px;
  width: var(--arrow-width);
  background: transparent;
  height: var(--arrow-stroke);
  position: relative;
  transition: 0.2s;
}

.view-all-btn .arrow::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  border: solid var(--secondary-color);
  border-width: 0 var(--arrow-stroke) var(--arrow-stroke) 0;
  display: inline-block;
  top: -3px;
  right: 3px;
  transition: 0.2s;
  padding: 3px;
  transform: rotate(-45deg);
}

.view-all-btn:hover {
  background-color: var(--hover-color);
  color: #fff;
}

.view-all-btn:hover .arrow {
  background: var(--secondary-color);
}

.view-all-btn:hover .arrow:before {
  right: 0;
}

.view-all-btn .arrow::before {
  border-color: #0051ff;
  right: 0;
}

.view-all-btn:hover .arrow::before {
  border-color: #fff;
}

@media (max-width: 768px) {
  .therapist-card {
    width: 100%;
    /* Make cards a little wider on mobile */
  }

  .therapist-name {
    font-size: 1em;
  }

  .therapist-username,
  .therapist-details {
    font-size: 0.8em;
  }

  .view-profile-btn {
    position: relative;
    bottom: 0;
    left: 0;
    transform: none;
    margin-top: auto;
    /* To push the button to the bottom in flex */
  }
}