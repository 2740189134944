.widget {
  height: 150px !important;
    display: flex;
    justify-content: space-between;
    flex: 1;
    padding: 10px;
    box-shadow: 0 0 0 0.5px rgb(190, 190, 190),
                0.2em 0.2em 0.5em rgba(0, 0, 0, 0.3);      
    border-radius: 10px;
    height: 100px;
  
    .left,
    .right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
  
      .title {
        font-weight: bold;
        font-size: 14px;
        color: rgb(160, 160, 160);
      }
  
      .counter {
        font-size: 28px;
        font-weight: 300;
      }
  
      .link {
        width: max-content;
        font-size: 12px;
        border-bottom: 1px solid gray;
      }
  
      .percentage {
        display: flex;
        align-items: center;
        font-size: 14px;
  
        &.positive {
          color: green;
        }
        &.negative {
          color: red;
        }
      }
  
      .icon {
        font-size: 25px;
        padding: 5px;
        border-radius: 5px;
        align-self: flex-end;
      }
    }
  }
  

  @media (maxWidth: 600px){
    .left .title{
       font-size: 10px !important;
  }

    .counter{
      font-size: 20px !important;
    }

    .link{
      font-size: 10px !important;
    }

    .percentage{
      font-size: 10px !important;
    }

    .widget-container {
      display: flex;
      flex-wrap: wrap;
    }
  
    .widget {
      flex: 1 1 calc(50% - 20px);
      margin-bottom: 10px;
      height: 120px !important;
    }

    .icon svg {
      width: 30px !important; 
      height: 30px;
    }
  }