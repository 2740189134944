.user_home {
  display: flex;
}

.user_home .homeContainer {
  flex: 6;
}

.bookingconf_cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 90%;
}

.booking_main_cont {
  display: flex;
}

.booking_user_card {
  position: relative;
  /* Required for pseudo-element positioning */
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
  text-align: center;
}

.user-image {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  object-fit: cover;
  margin-bottom: 15px;
  margin-top: 30px;
  position: relative;
  /* Ensure it stays above the pseudo-element */
  z-index: 1;
}

.therapist-name {
  font-size: 1.7em;
  margin: 0.5em 0;
  margin-bottom: 0.2em;
}

.therapist-subheading {
  font-size: 1.2em;
  color: #777;
  margin: 0.7em 0;
  margin-top: 0 !important;
}

.therapist-areas {
  font-size: 1em;
  margin: 0;
  color: #555;
}

.therapist-languages {
  font-size: 1em;
  margin: 0;
  color: #555;
}

.therapist-rating {
  margin-top: 10px;
  margin-bottom: 20px;
}

.session_details {
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  max-width: 600px;
  margin-top: 20px;
}

.session-heading {
  font-size: 1.5em;
  margin-bottom: 10px;
  margin-left: 15%;
  font-weight: 600;
}

.session-info {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.video-icon {
  font-size: 3em;
  color: #007bff;
}

.session-text {
  text-align: left;
}

.session-type {
  font-size: 1.2em;
  margin: 0;
}

.session-date {
  font-size: 1em;
  color: #555;
}

.bookimg_cont {
  position: relative;
  /* To position the pseudo-element relative to this container */
}

.bookimg_cont::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 55%;
  background-color: #4cc9f0;
  /* Background color */
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  z-index: 0;
  border-bottom: 5px solid #0096c7;
}

.bill_container {
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  max-width: 600px;
  margin-top: 20px;
}

.bill-item {
  display: flex;
  justify-content: space-between;
  margin: 8px 0;
  font-size: 1em;
}

.bill-item.total {
  font-size: 1.3em;
  font-weight: bold;
  color: #007bff;
}

.bill-item.final {
  font-weight: bold;
  font-size: 1.2em;
}

.bill_divider {
  border-bottom: 1px solid #c9c9c9;
  padding-bottom: 10px;
}

.span_bold {
  font-size: 1.1em;
  font-weight: 600;
}

/* Existing styles... */

.bill_container,
.client_container {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  width: 600px;
}

.bill-heading,
.client-heading {
  font-size: 1.5em;
  margin-bottom: 15px;
  font-weight: 600;
  color: #333;
}

.bill-item,
.client-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.bill_divider,
.client-item {
  /* border-top: 1px solid #e0e0e0; */
  padding-top: 10px;
}

.span_bold {
  font-weight: bold;
}

.proceed_and_pay_cont {
  margin-top: 20px;
  width: 600px;
  padding: 10px;
  background-color: white;
  padding-bottom: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.payment-summary {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}

.total-payable {
  display: flex;
  flex-direction: column;
  font-size: 1.2em;
  font-weight: bold;
}

.proceed-btn {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
  font-size: 0.8em;
}

.proceed-btn:hover {
  background-color: #0056b3;
  font-weight: 600;
  transform: scale(1.1);
  transition: all 0.3s ease-in-out 0s;
}

.credit-option {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.credit-option input[type="checkbox"] {
  margin-right: 10px;
}

.coins-icon {
  margin-left: 5px;
  color: #ffcc00;
  /* Gold color for the coins */
}

.balance-text {
  font-size: 0.9em;
  color: #555;
}

.total_head {
  font-size: 0.8em !important;
  font-weight: 500 !important;
}

.total_amt {
  font-size: 1.2em;
}

@media (max-width: 768px) {

  .bill_container,
  .client_container,
  .proceed_and_pay_cont {
    width: auto;
    min-width: 100%;
  }
}