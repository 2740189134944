
.call_request_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(51, 51, 51, 0.5); 
  z-index: 1000; 
}

.call_request_container {
  width: 100%;
  max-width: 400px;
  height: auto;
  border: 1px solid black;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed; 
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); 
  z-index: 1001;
}


.user_icon {
  font-size: 3.3rem !important; 
  margin-right: 20px;
  color: gray; 
  margin-bottom: 10px;
}

.call_request_content h3 {
  font-size: 1.5rem;
  margin-bottom: 50px;
  color: #333;
  text-align: center;
  font-weight: 500;
}

.call_request_details {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 30px;
}

.user_name {
  font-size: 1.8rem;
  font-weight: bold;
  margin: 0;
}

.call_request_buttons {
  display: flex;
  justify-content: center;
  gap: 50px;
  margin-top: 20px;
}

.callBtn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 120px;
  height: 40px;
  border: none;
  padding: 0 20px;
  color: white;
  font-weight: 500;
  cursor: pointer;
  border-radius: 30px;
  box-shadow: 5px 5px 0px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease;
}

.callBtn.answer {
  background-color: #29bf12; 
  box-shadow: 5px 5px 0px #1f8e0d;
}

.callBtn.reject {
  background-color: rgb(255, 76, 76); 
  box-shadow: 5px 5px 0px rgb(204, 41, 41);
}

.callsvg {
  width: 15px;
  position: absolute;
  right: 10px; 
  fill: white;
  transition: right 2s ease, margin-right 2s ease;
}

.callBtn span {
  transition: opacity 0.3s ease; 
}

.callBtn.answer:hover {
  background-color: #29a117; 
  box-shadow: 5px 5px 0px #135e07;
}

.callBtn.reject:hover {
  background-color: rgb(255, 54, 54); 
  box-shadow: 5px 5px 0px rgb(204, 41, 41);
}

.callBtn:hover .callsvg {
  right: 46px; 
}

.callBtn:hover span {
  opacity: 0; 
}

.callBtn:active {
  transform: translate(3px, 3px);
  box-shadow: 2px 2px 0px;
}


@media (max-width: 600px) {
  .call_request_container {
    padding: 15px;
    width: 90%;
  }

  .call_request_content {
    text-align: left;
  }

  .call_request_content h3{
    font-size: 1.8rem;
    margin-bottom: 20px;
  }

  .call_request_details {
    flex-direction: column;
    align-items: center;
  }

  .user_icon {
    font-size: 8rem !important; 
    margin-right: 0;
    margin-bottom: 10px;
  }

  .user_name {
    font-size: 1.8rem; 
  }

  .call_request_buttons {
    gap: 20px;
    margin-bottom: 20px;
  }

 
}
