.therapy_home {
    display: flex;
  
    .homeContainer {
      flex: 6;
  
      .widgets,
      .charts {
        display: flex;
        padding: 0px 40px;
        gap: 20px;
      }
  
      .charts {
        padding: 5px 20px;
      }
  
      .listContainer {
        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
        padding: 20px;
        margin: 20px;
  
        .listTitle {
          font-weight: 500;
          color: gray;
          margin-bottom: 15px;
        }
      }
    }
  }
  

  .third_container{
    display: flex;
    margin-top: 30px !important;
  }

  .fourth_container{
    display: flex;
    margin-top: 0;
    
  }

  @media (max-width: 600px){
    .third_container,.fourth_container{
      flex-direction: column !important;
    }

    .third_container{
      margin-top: 10px !important;
    }

    .widgets{
      flex-wrap: wrap;
      flex-direction: column !important;
    }

    .therapy_home .homeContainer{
      width: 90%;
    }

    .therapy_home .homeContainer .widgets, .therapy_home .homeContainer .charts{
      flex-direction: column !important;
    }
  }

  @media (max-width: 850px){
    .fourth_container,.third_container,.widgets{
      flex-direction: column !important;
    }
    .therapy_home .homeContainer{
      width: 90%;
    }

    .third_container,.fourth_container{
      width: 100% !important;
      
      align-items: center;
    }
  }

  @media (max-width: 1100px){
    .fourth_container,.third_container,.widgets{
      flex-direction: column !important;
    }
  }