/* TherapistsProfileCard.css */

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400&display=swap');

.therapists-profile-container {
    width: 90%;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Lato', sans-serif;
}

.section-selector {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.section-selector button {
    width: 50%;
    padding: 10px 20px;
    border: none;
    /* border-radius: 5px; */
    /* margin: 0 10px; */
    cursor: pointer;
    background-color: #f0f0f0;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.section-selector button.active,
.section-selector button:hover {
    /* background-color: #007bff; */
    border-bottom: 2px solid #007bff;
    color: #007bff;
    background-color: #d7d7d7;
    border-right: 2px solid #b8b8b8;
}

.cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 20px;
}

.profile-card {
    width: 330px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Lato', sans-serif;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    /* Ensures items are spaced evenly */
    position: relative;
    /* For absolute positioning */
    height: auto !important;
}

.profile-header {
    position: relative;
    background: linear-gradient(60deg, rgba(84, 58, 183, 1) 0%, rgba(0, 172, 193, 1) 100%);
    height: 180px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    /* overflow: hidden;  */
}

.profile-pic {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 5px solid white;
    position: absolute;
    bottom: -20px;
    z-index: 10;
}

.waves {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 60px;
    margin-bottom: -7px;
    min-height: 60px;
    max-height: 100px;
}

.parallax>use {
    animation: move-forever 25s cubic-bezier(.55, .5, .45, .5) infinite;
}

.parallax>use:nth-child(1) {
    animation-delay: -2s;
    animation-duration: 7s;
}

.parallax>use:nth-child(2) {
    animation-delay: -3s;
    animation-duration: 10s;
}

.parallax>use:nth-child(3) {
    animation-delay: -4s;
    animation-duration: 13s;
}

.parallax>use:nth-child(4) {
    animation-delay: -5s;
    animation-duration: 20s;
}

@keyframes move-forever {
    0% {
        transform: translate3d(-90px, 0, 0);
    }

    100% {
        transform: translate3d(85px, 0, 0);
    }
}

.profile-details {
    text-align: center;
    padding: 40px 20px 10px 20px;
}

.profile-name {
    font-size: 24px;
    margin-bottom: 10px;
    font-weight: bold;
}

.profile-status {
    position: absolute;
    top: 0px;
    /* Adjust this value as needed */
    right: 0px;
    padding: 5px 10px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    font-weight: bold;
}

.status {
    font-weight: bold;
    color: #fff;
}

.languages {
    margin-bottom: 5px;
    font-size: 14px;
    color: #777;
}

.profile-rating {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 15px;
}

.cost {
    font-size: 23px;
    padding-left: 30px;
    font-weight: bold;
    color: #007bff;
}

.rating {
    padding-top: 3px;
    font-size: 19px;
    padding-right: 30px;
    font-weight: 500;
}

.profile-bio {
    padding-top: 15px !important;
    padding: 0 10px;
    font-size: 14px;
    color: #a7a6a6;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* From Uiverse.io by cssbuttons-io */
.viewProfileBtn {
    --color: dodgerblue;
    font-family: inherit;
    display: inline-block;
    width: 8em;
    height: 2.6em;
    line-height: 2.5em;
    margin: 20px;
    margin-top: 10px;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    border: 2px solid var(--color);
    transition: color 0.5s;
    z-index: 1;
    font-size: 17px;
    border-radius: 30px;
    font-weight: 500;
    color: var(--color);
    background: transparent !important;
    margin-top: auto;
}

.viewProfileBtn:before {
    content: "";
    position: absolute;
    z-index: -1;
    background: var(--color);
    height: 150px;
    width: 200px;
    border-radius: 50%;
}

.viewProfileBtn:hover {
    color: #fff;
}

.viewProfileBtn:before {
    top: 100%;
    left: 100%;
    transition: all 0.7s;
}

.viewProfileBtn:hover:before {
    top: -30px;
    left: -30px;
}

.viewProfileBtn:active:before {
    background: #3a0ca3;
    transition: background 0s;
}

.therapdetailsContainer {
    display: flex;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .cards-container {
        flex-direction: column;
        align-items: center;
    }

    .profile-card {
        width: 90%;
    }
}