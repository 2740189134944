// .therapist-navbar {
//   height: 65px;
//   border-bottom: 1px solid #fff;
//   box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.2);
//   display: flex;
//   align-items: center;
//   font-size: 14px;
//   color: #555;

//   .wrapper {
//     width: 100%;
//     padding: 0 20px;
//     display: flex;
//     align-items: center;
//     justify-content: space-between;

//     .search {
//       display: flex;
//       align-items: center;
//       padding: 3px;

//       .thapy_logo {
//         height: 65px;
//         margin-right: 20px;
//       }
//     }

//     .items {
//       display: flex;
//       align-items: center;

//       .avatar-container{
//         margin-bottom: 10px;
//       }

//       .full-screen{
//         cursor: pointer;
//       }

//       .item {
//         display: flex;
//         align-items: center;
//         margin-right: 20px;
//         position: relative;

//         .icon {
//           font-size: 20px;
//         }

//         .avatar {
//           width: 40px;
//           height: 40px;
//           border-radius: 50%;
//         }

//         .counter {
//           width: 15px;
//           height: 15px;
//           background-color: red;
//           border-radius: 50%;
//           color: white;
//           display: flex;
//           align-items: center;
//           justify-content: center;
//           font-size: 10px;
//           font-weight: bold;
//           position: absolute;
//           top: -5px;
//           right: -5px;
//         }
//       }
//       .item-counter:hover {
//         cursor: pointer;
//         .icon{
//           transform: scale(1.3);
//           transition: all 0.1s ease-in-out;
//         }
//         .counter{
//           top: -10px;
//           right: -5px;
//           transform: scale(1.25);
//           transition: all 0.2s ease-in-out;
//           border-radius: 100%;
//           padding: 5px;
//         }
//       }    
//     }
//   }

//   @media (max-width: 768px) {
//     .wrapper {
//       padding: 0 10px;

//       .search {
//         .thapy_logo {
//           height: 35px;
//         }
//       }

//       .items {
//         .item {
//           margin-right: 10px;

//           .icon {
//             font-size: 18px;
//           }

//           .avatar {
//             width: 25px;
//             height: 25px;
//           }

//           .counter {
//             width: 12px;
//             height: 12px;
//             font-size: 8px;
//           }
//         }
//       }
//     }
//   }

//   @media (max-width: 480px) {
//     .wrapper {
//       flex-direction: row;
//       justify-content: space-between;

//       .search {
//         .thapy_logo {
//           height: 30px;
//         }
//       }

//       .items {
//         .item {
//           .icon {
//             font-size: 16px;
//           }

//           .avatar {
//             width: 20px;
//             height: 20px;
//           }

//           .counter {
//             width: 10px;
//             height: 10px;
//             font-size: 6px;
//           }
//         }
//       }
//     }
//   }
// }

.icon-btn-navbar:hover{
  transition: all 0.2s ease-in-out 0s;
    transform: scale(1.15);
}
