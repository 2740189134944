.home {
    text-align: center;
    color: green;
    background-color: black;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: calc(10px + 2vmin);
    color: white;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    position: relative;
    overflow-x: hidden;
    background-size: cover;
    background-position: center;
    margin-bottom: 70px;
}

.home-header {
    width: 100%;
    position: absolute;
    top: 140px;
    left: 0;
    background-color: transparent;
}

.brand-name {
    font-family: 'Verdana', sans-serif;
    color: transparent;
    background-image: linear-gradient(to right, #ffffff 0%, rgb(126, 255, 163) 100%);
    -webkit-background-clip: text;
    background-clip: text;
    text-shadow: 2px 2px 8px rgba(16, 147, 53, 0.4);
    margin-left: 0;
}

.brand-name h1 {
    font-size: 66px;
    margin-bottom: 0;
    font-weight: bolder;
}

.brand-name h2 {
    font-size: 34px;
}

.we-listen {
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 0;
    padding: 0;
    color: rgb(255, 255, 255);
    text-shadow:
        0 0 5px rgb(0, 255, 98),
        0 0 40px rgb(0, 255, 98);
}

.we-listen span {
    color: var(--accent-color);
    font-size: 40px;
    font-weight: 600;
    padding-left: 3px;
    text-shadow:
        0 0 5px var(--accent-color),
        0 0 40px var(--accent-color);
}

.main-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 60px;
    position: relative;
    margin-top: 220px;
}

.hiring-info-container {
    background-color: rgba(255, 255, 255, 0);
    border-radius: 10px;
    padding: 40px;
    box-shadow: 0 4px 20px rgba(255, 255, 255, 0);
    text-align: center;
    margin-bottom: 10vh;
    transform: translateZ(0);
    transition: transform 0.3s ease-in-out;
}

.hiring-info-container:hover {
    transform: scale(1.03);
}

.hiring-info h3 {
    margin: 0;
    color: rgb(106, 217, 106);
}


.apply-button button {
    font-size: 20px;
    padding: 15px 30px;
    background-color: transparent;
    color: rgb(106, 217, 106);
    border: 2px solid rgb(106, 217, 106);
    border-radius: 30px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, transform 0.3s;
    margin-top: 30px;
}

.apply-button button:hover {
    background-color: rgb(106, 217, 106);
    color: black;
    font-weight: bold;
    border-color: black;
    transform: scale(1.05);
}

@media screen and (max-width: 768px) and (orientation: portrait) {
    .app {
        background-attachment: scroll;
        overflow-y: hidden;
    }

    .home-header {
        top: 100px;
    }

    .main-content {
        top: 10vh;
        margin-top: 160px;
        padding: 20px;
    }

    .brand-name {
        font-size: 32px;
    }

    .we-listen {
        font-size: 1em;
    }

    .hiring-info-container {
        margin-bottom: 20vh;
    }

}

@media screen and (max-width: 768px) and (orientation: landscape) {
    .app {
        min-height: 600px;
        overflow-y: auto;
    }

    .main-content {
        top: 10vh;
        padding: 20px;
        margin-top: 70px;
    }

    .home-header {
        top: 40px;
    }

    .brand-name {
        font-size: 32px;
    }

    .we-listen {
        font-size: 1em;
    }

    .hiring-info-container {
        margin-bottom: 20vh;
    }

}

/*--------------------------------------------------------------
# About Section
--------------------------------------------------------------*/

.about {
    --background-color: #f4f4f4;
    margin-bottom: 70px;
}

.about .content h2 {
    font-size: 20px;
    font-weight: 500;
    line-height: 19px;
    padding: 10px 20px;
    background: rgba(var(--accent-color-rgb), 0.05);
    color: var(--accent-color);
    border-radius: 7px;
    display: inline-block;
}

.about .content p:last-child {
    margin-bottom: 0;
}

.about .content .read-more {
    background: var(--accent-color);
    color: var(--contrast-color);
    font-family: var(--heading-font);
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 1px;
    padding: 12px 24px;
    border-radius: 5px;
    transition: 0.3s;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.about .content .read-more i {
    font-size: 18px;
    margin-left: 5px;
    line-height: 0;
    transition: 0.3s;
}

.about .content .read-more:hover {
    background: rgba(var(--accent-color-rgb), 0.8);
    padding-right: 19px;
}

.about .content .read-more:hover i {
    margin-left: 10px;
}

.about .icon-box {
    padding: 50px 40px;
    box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    transition: all 0.3s ease-out 0s;
    background-color: #fff;
}

.about .icon-box i {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
    font-size: 32px;
    line-height: 0;
    transition: all 0.4s ease-out 0s;
    background-color: rgba(var(--accent-color-rgb), 0.05);
    color: var(--accent-color);
}

.about .icon-box h3 {
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: 700;
}

.about .icon-box p {
    margin-bottom: 0;
}

.about .icon-box:hover {
    transform: scale(1.08);
    box-shadow: 0px 0 30px rgba(55, 55, 63, 0.15);
}

.about .icon-box:hover i {
    background-color: var(--accent-color);
    color: var(--contrast-color);
}

.about .icon-boxes .col-md-6:nth-child(2) .icon-box,
.about .icon-boxes .col-md-6:nth-child(4) .icon-box {
    margin-top: -40px;
}

@media (max-width: 768px) {

    .about .icon-boxes .col-md-6:nth-child(2) .icon-box,
    .about .icon-boxes .col-md-6:nth-child(4) .icon-box {
        margin-top: 0;
    }
}

/*--------------------------------------------------------------
# Section Title Section
--------------------------------------------------------------*/

.section-title {
    text-align: center;
    padding-bottom: 40px;
}

.section-title h1 {
    font-size: 40px;
    font-weight: 700;
    position: relative;
    color: black;
}

.section-title h1:before,
.section-title h1:after {
    content: "";
    width: 50px;
    height: 2px;
    background: var(--accent-color);
    display: inline-block;
}

.section-title h1:before {
    margin: 0 15px 10px 0;
}

.section-title h1:after {
    margin: 0 0 10px 15px;
}

/*--------------------------------------------------------------
# Sections & Section Header
--------------------------------------------------------------*/

.section-header {
    text-align: center;
    padding: 0 0 20px 0;
    position: relative;
}

.section-header h2 {
    font-size: 32px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 20px;
    padding-bottom: 0;
    color: #001973;
    position: relative;
    z-index: 2;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
}

.section-header h2:after {
    content: " ";
    position: absolute;
    display: block;
    width: 50px;
    height: 3px;
    background: var(--accent-color);
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

.section-header p {
    margin-bottom: 0;
    position: relative;
    z-index: 2;
}

@media (max-width: 640px) {
    .section-header h2 {
        font-size: 28px;
        margin-bottom: 15px;
    }

    .section-header span {
        font-size: 38px;
    }
}

/*--------------------------------------------------------------
# Our Services Section
--------------------------------------------------------------*/

.services {
    --background-color: #f4f4f4;
    margin-top: 40px;
    margin-bottom: 70px;
}

.services .icon-box {
    text-align: center;
    border: 1px solid #d5e1ed;
    padding: 80px 20px;
    transition: all ease-in-out 0.3s;
}

.services .icon-box .icon {
    margin: 0 auto;
    width: 64px;
    height: 64px;
    background: var(--accent-color);
    border-radius: 5px;
    transition: all 0.3s ease-out 0s;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    transform-style: preserve-3d;
    position: relative;
    z-index: 2;
}

.services .icon-box .icon i {
    color: #fff;
    font-size: 28px;
    transition: ease-in-out 0.3s;
}

.services .icon-box .icon::before {
    position: absolute;
    content: "";
    left: -8px;
    top: -8px;
    height: 100%;
    width: 100%;
    background: rgba(var(--accent-color-rgb), 0.3);
    border-radius: 5px;
    transition: all 0.3s ease-out 0s;
    transform: translateZ(-1px);
    z-index: -1;
}

.services .icon-box h4 {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 24px;
    color: #2c4964;
}

.services .icon-box p {
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
}

.services .icon-box:hover {
    border-color: var(--accent-color);
    transform: scale(1.08);
    box-shadow: 0px 0 30px rgba(55, 55, 63, 0.15);
}

.services .icon-box:hover .icon {
    background: rgba(var(--accent-color-rgb), 0.3);
    border-color: var(--accent-color);
}

.services .icon-box:hover .icon i {
    color: var(--accent-color);
}

.services .icon-box:hover .icon::before {
    background: rgba(var(--accent-color-rgb), 0.03);
}

/*--------------------------------------------------------------
# Scroll Top Section
--------------------------------------------------------------*/

.scroll-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 15px;
    bottom: 15px;
    z-index: 99999;
    background-color: var(--accent-color);
    width: 40px;
    height: 40px;
    border-radius: 4px;
    transition: all 0.4s;
}

.scroll-top i {
    font-size: 24px;
    color: #ffffff;
    line-height: 0;
}

.scroll-top:hover {
    background-color: rgba(var(--accent-color-rgb), 0.8);
    color: #ffffff;
}

.scroll-top.active {
    visibility: visible;
    opacity: 1;
}

/*--------------------------------------------------------------
# Gallery Section
--------------------------------------------------------------*/
.gallery .gallery-member {
    overflow: hidden;
    text-align: center;
    border-radius: 5px;
    background: #fff;
    box-shadow: 0px 0 30px rgba(55, 55, 63, 0.08);
    transition: 0.3s;
}

.gallery .gallery-member .member-img {
    position: relative;
    overflow: hidden;

}

.gallery .gallery-member .member-img:after {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background-size: contain;
    z-index: 1;
}

.gallery .gallery-member:hover {
    transform: scale(1.08);
    box-shadow: 0px 0 30px rgba(55, 55, 63, 0.15);
}

/*--------------------------------------------------------------
# FAQ Section
--------------------------------------------------------------*/
.container-faq {
    margin: 50px 50px 0;
}

@media (min-width: 1200px) {
    .container-faq {
        margin: 50px 4rem 3.5rem;
        padding: 0px 2rem 50px;
    }
}

@media (max-width: 1200px) {
    .container-faq {
        margin: 50px 0.125rem 0;
        padding: 0px 3.125rem 3.125rem;
    }
}

@media (max-width: 992px) {
    .container-faq {
        margin: 50px 30px 0;
        padding: 0px 30px 50px;
    }
}

@media (max-width: 768px) {
    .container-faq {
        margin: 50px 20px 0;
        padding: 0px 20px 50px;
    }
}

@media (max-width: 576px) {
    .container-faq {
        margin: 50px 0px 0;
        padding: 0px 0px 50px;
    }
}

.container-faq .card {
    box-shadow: 0 20px 27px 0 rgb(0 0 0 / 5%);
    border: none;
    margin-bottom: 0px;
    padding-bottom: 20px;
    border-radius: 20px;
}

.container-faq .card p {
    text-align: justify;
}

.container-faq .nav-tabs {
    border-bottom: 1px solid #dee2e6;
}

.container-faq .nav-item .nav-link {
    width: 100%;
}

.container-faq .nav-tabs-custom .nav-item .nav-link.active {
    color: #6c6ff5;
    border-bottom: 1px solid #dee2e6;
}

.container-faq .font-size-18 {
    font-size: 18px;
    text-transform: none;
    font-weight: 400;
}

.container-faq .nav-tabs-custom {
    margin-top: -20px;
}

.container-faq .nav-tabs-custom .nav-item .nav-link {
    color: #0d6efd;
    border-bottom: 1px solid #dee2e6;
}

.container-faq .text-muted {
    color: #6c7681 !important;
}

.container-faq .nav-tabs .nav-link:hover {
    background-color: white;
}

.container-faq .nav-tabs-custom .nav-item {
    position: relative;
    color: #271050;
}

.container-faq .nav-tabs-custom .nav-item .nav-link.active:after {
    -webkit-transform: scale(1);
    transform: scale(1);
}

.container-faq .nav-tabs-custom .nav-item .nav-link::after {
    content: "";
    background: #6c6ff5;
    height: 2px;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -1px;
    -webkit-transition: all 250ms ease 0s;
    transition: all 250ms ease 0s;
    -webkit-transform: scale(0);
    transform: scale(0);
}

.therapistHomeList {
    display: flex;
    height: 100%;
}

.therapistHomeListContainer {
    flex: 1;
    width: 90%;
    margin-top: 80px !important;
}


/* Home Psychologist Card */

.home-psychologist-cards {
    padding: 30px;
    margin-bottom: 15px;
    font-family: Arial, sans-serif;
}

.home-psychologist-cards .therapist-card-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
}

.home-psychologist-cards .therapist-card-row {
    display: flex;
    gap: 20px;
    overflow-x: hidden;
    padding: 0px 20px;
}

.home-psychologist-cards .therapist-card {
    background-color: white;
    border-radius: 15px;
    margin: 10px 0px;
    padding: 20px;
    width: 300px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    height: auto !important;
}

.home-psychologist-cards .therapist-card:hover {
    transform: scale(1.05);
    transition: ease-in-out 0.3s;
}

.home-psychologist-cards .therapist-image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 15px;
}

.home-psychologist-cards .therapist-name {
    margin: 0;
    font-size: 1.3em;
    font-weight: bold;
}

.home-psychologist-cards .therapist-username {
    color: #666;
    margin: 5px 0px 0px;
    font-size: 0.85em;
    font-weight: 600;
}

.home-psychologist-cards .therapist-details {
    color: #666;
    margin: 0px 0 15px;
    font-size: 0.9em;
}

.home-psychologist-cards .therapist-bio {
    font-size: 0.9em;
    margin-bottom: 20px;
    color: #333;
    line-height: 1.4;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.home-psychologist-cards .therapist-stats {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    font-size: 0.9em;
}

.home-psychologist-cards .therapist-stats div {
    display: flex;
    align-items: center;
}

.home-psychologist-cards .therapist-stats strong {
    margin-right: 5px;
}

.home-psychologist-cards .view-profile-btn {
    background-color: rgba(var(--accent-color-rgb), 0.05);
    color: var(--accent-color);
    border: none;
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 0.9em;
    font-weight: 500;
    width: 100%;
    transition: background-color 0.3s;
    margin-top: auto;
}

.home-psychologist-cards .view-profile-btn:hover {
    transform: scale(1.05);
    transition: ease-in-out 0.3s;
    background-color: var(--accent-color);
    color: white;
}

.home-psychologist-cards .nav-btn {
    background: white;
    border: none;
    font-size: 1.5em;
    cursor: pointer;
    color: var(--accent-color);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s;
}

.home-psychologist-cards .nav-btn:hover {
    background-color: #f0f0f0;
}

.home-psychologist-cards .nav-btn:disabled {
    color: #ccc;
    background-color: #f0f0f0;
    cursor: not-allowed;
}

.home-psychologist-cards .prev {
    margin-right: 10px;
}

.home-psychologist-cards .next {
    margin-left: 10px;
}

.home-psychologist-cards .user_card_flex {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.home-psychologist-cards .user_details_cont {
    margin-left: 20px;

}

.home-psychologist-cards .therap_price {
    font-size: 20px !important;
}

.home-psychologist-cards .therap_profile_heading {
    font-size: 35px;
    margin-bottom: 20px;
    font-weight: 600;
    text-align: center;
    color: #001d3d;
}

.home-psychologist-cards .view_cont {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.home-psychologist-cards .view-btn {
    background: var(--accent-color);
    color: white;
    font-family: var(--heading-font);
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 1px;
    border: 2px solid white;
    padding: 12px 24px;
    border-radius: 5px;
    transition: 0.3s;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.home-psychologist-cards .view-btn i {
    font-size: 18px;
    margin-left: 5px;
    line-height: 0;
    transition: 0.3s;
}

.home-psychologist-cards .view-btn:hover {
    background: rgba(var(--accent-color-rgb), 0.8);
    padding-right: 19px;
}

.home-psychologist-cards .view-btn:hover i {
    margin-left: 10px;
}

@media (max-width: 768px) {
    .therapist-card {
        width: 100%;
    }

    .therapist-name {
        font-size: 1em;
    }

    .therapist-username,
    .therapist-details {
        font-size: 0.8em;
    }

    .view-profile-btn {
        position: relative;
        bottom: 0;
        left: 0;
        transform: none;
        margin-top: auto;
    }
}