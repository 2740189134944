* {
  padding: 0;
  margin: 0;
  font-family: sans-serif;
}
//Welcome Card

.welcome-card {
  border-radius: 10px;
  margin: 40px;
  width: auto;
  height: 350px;
  background: rgb(4, 207, 247);
  background-image: url(../../Assets/hero-card-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  margin: 40px;
  width: auto;
  height: 350px;
  background-repeat: no-repeat;
  background-size: cover;
}

.welcome-card .shadow {
  box-shadow: inset 0 -2em 2em rgba(0, 0, 0, 0.1),
    0 0 0 0.5px rgb(190, 190, 190), 0.2em 0.2em 0.5em rgba(0, 0, 0, 0.3);
}

.welcome-card .inner-card {
  height: 200px;
  width: auto;
  margin: 20px;
  border-radius: 10px;
  background: #d0ecff;
  background: radial-gradient(at center, #ffffff, #ddf1ff);
}

.welcome-card h2 {
  color: white;
  text-align: center;
  font-family: sans-serif;
  font-weight: bold;
  padding-top: 15px;
}

.welcome-card .inner_section {
  display: flex;
}

.main_heading {
  font-size: 30px;
}

.welcome-card .image {
  height: 130px;
  margin: 20px;
  margin-right: 0;
  margin-left: 0;
}

.welcome-card .section {
  display: flex;
  justify-content: space-around !important;
}

.payment {
  padding-top: 50px;
  padding-left: 20px;
}

.welcome-icon {
  margin-left: 3px;
  font-size: smaller;
  padding: 4px;
  color: rgb(166, 166, 166) !important;
  cursor: pointer;
}

.welcome-card .date {
  color: #ffffff;
  text-align: center;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 600;
}

.inner_section{
  margin-right: 20px !important;
}

.welcome-card .currency {
  font-size: 20px;
  color: rgb(145, 144, 144);
  font-weight: 600;
}

.welcome-card .amount {
  font-size: 40px;
  padding-left: 3px;
  font-weight: bolder;
}

.welcome-icon {
  margin-left: 3px;
  font-size: smaller;
  padding: 4px;
  color: rgb(166, 166, 166) !important;
  ;
}


.welcome-card .decimal {
  font-size: 20px;
  color: rgb(145, 144, 144);
  padding-left: 3px;
}

.welcome-card .current_balance {
  color: black;
}

.welcome-card .currency_heading {
  font-weight: bolder;
  font-size: larger;
}

.welcome-card .middle_element {
  display: flex;
  align-items: center;
}

.welcome-card .check_icon {
  padding: 0.1em;
  margin-right: 10px;
}

.welcome-card .icon-and-line {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 8px;
}
.welcome-card .settlement {
  margin-top: 5px;
}



.welcome-card .processed {
  color: rgb(23, 175, 81);
}

.welcome-card .blue_span,
.arrow {
  cursor: pointer;
  color: rgb(0, 89, 255);
}

.welcome-card .money {
  color: black;
  margin: 0;
}

.welcome-card .arrow {
  font-size: 20px !important;
  margin-bottom: 0.1em !important;
}

.welcome-card .para {
  font-size: 14px;
  color: rgb(72, 71, 71);
  font-weight: 600;
}

.welcome-card .dot{
  font-size: 10px;
  margin: 0px 5px;
  color: rgb(167, 165, 165);
}

.welcome-card .second {
  margin-left: 5px !important;
}

.vertical-line {
  width: 2px;
  background-color: rgb(208, 208, 208);
  height: 100px;
  margin-top: 4px;
  margin-right: 9px;
}

@media (max-width: 768px) {
  .welcome-card .card {
    height: 250px;
  }

  .welcome-card {
    margin: 15px;
    height: auto;
  }

  .inner-card{
    height: auto;
  }

  .welcome-card h2 {
    font-size: 20px;
  }

  .welcome-card .date {
    font-size: 13px;
    text-align: center !important;
  }

  .welcome-card .inner-card {
    margin-top: 4px;
    height: 170px !important;
  }

  .welcome-card .image {
    display: none;
  }

  .welcome-card .currency_heading {
    font-size: 12px;
  }

  .welcome-card .amount {
    font-size: 20px;
  }

  .welcome-card .currency,
  .decimal {
    font-size: 13px;
  }

  .welcome-card .para {
    font-size: 0.60em;
    padding-right: 5px !important;
    padding-top: 8px !important;
  }

  .welcome-card .arrow {
    font-size: 10px !important;
  }

  .welcome-card .icon-and-line {
    margin: 0;
  }

  .welcome-card .dot {
    color: rgb(167, 165, 165);
    margin-right: 2px;
  }

  .welcome-card .second {
    margin-left: 0px !important;
  }

  .welcome-card .main_heading {
    font-size: 20px;
  }

  .welcome-card .middle_element {
    margin-top: 15px;
  }
}
