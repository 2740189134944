.line_card {
  width: 67%;
  margin: 40px;
  margin-top: 0;
  height: auto;
  border-radius: 10px;
  background: white;
  box-shadow: 0 0 0 0.5px rgb(190, 190, 190),
    0.2em 0.2em 0.5em rgba(0, 0, 0, 0.3);
  padding: 20px;
}

.chart-container {
  position: relative;
  height: 400px;
  /* Set a fixed height for the chart container */
}

@media (maxWidth: 600px) {
  .line_card {
    padding: 5px;
    width: 90%;
    margin: 0px auto;
    overflow-x: hidden;
  }

  .line_card .chartjs-size-monitor,
  .line_card canvas {
    width: 100% !important;
  }

  .chart-container {
    height: 250px;
    /* Adjust the height for mobile view */
  }

  .line_card .chartjs-render-monitor {
    font-size: 1px;
  }

  .line_card .chartjs-chart .bar {
    width: 5px !important;
    margin: 0 5px !important;
  }

  .graph_heading {
    font-size: 15px !important;
    padding: 5px;
  }
}

@media (max-width: 850px) {
  .line_card {
    padding: 5px;
    width: 90%;
    margin: 0px auto;
    overflow-x: hidden;
    margin-bottom: 20px;
  }

  .line_card .chartjs-size-monitor,
  .line_card canvas {
    width: 100% !important;
  }
}

@media (max-width: 1100px) {
  .line_card {
    width: 90%;
  }
}

.no-data {
  color: #888;
  text-align: center;
  padding: 20px;
}