.overview-container {
    margin: 30px;
    padding-top: 12px;
    max-width: 100%;
    background: rgb(4, 207, 247);
    background-image: linear-gradient(193deg, rgb(21, 102, 241) -194.8%, rgb(45, 34, 87) 143.88%);
    background-repeat: no-repeat;
    background-size: cover;
    height: 260px;
    border-radius: 10px;
    box-shadow: inset 0 -2em 2em rgba(0, 0, 0, 0.5),
        0 0 0 0.5px rgb(190, 190, 190), 0.2em 0.2em 0.5em rgba(0, 0, 0, 0.3);

    .overview-heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;
        font-weight: bold;
        color: white;

        span {
            font-size: 1em;
        }

        .overview-time {
            color: #c5c7c9;
            align-items: center;
        }

        .overview-heading {
            color: #4f4f4f;
            font-weight: bolder;
            font-size: larger;
            align-items: center;
        }

        .refresh {
            display: flex;
            align-items: center;

            span {
                margin-right: 10px;
            }

            button {
                text-transform: none;
                margin-left: 10px;
            }
        }
    }

    .overview_content {
        margin-left: 15px;
        margin-right: 15px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        .overview-item {
            flex: 1;
            padding: 10px;
            margin: 0 10px;
            background: #fff;
            text-align: center;
            box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.4);
            border-radius: 5px;
    border-width: 1px;
    border-color: rgba(108, 132, 157, 0.18);

            &:hover {
                transition: all 0.3s ease-in-out 0s;
                transform: scale(1.1);
                box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.7);
            }

            .item-title {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 1.3em;
                color: rgb(25, 40, 57);;
                font-weight: 550;

                svg {
                    margin-left: 5px;
                    font-size: 1em;
                }
            }

            .item-value {
                display: block;
                font-size: 1.5em;
                font-weight: bold;
                margin: 10px 0;
            }

            .overview-decimal {
                font-size: 20px;
                color: rgb(145, 144, 144);
                padding-left: 3px;
            }

            .overview-currency {
                font-size: 20px;
                color: rgb(145, 144, 144);
                font-weight: 600;
            }

            .item-status {
                display: inline-block;
                padding: 2px 6px;
                font-size: 0.8em;
                border-radius: 4px;

                &.processed {
                    background: #d4edda;
                    color: #155724;
                }
            }

            .item-info {
                font-size: 0.8em;
                color: #6c757d;
            }

            button {
                margin-top: 10px;
            }
        }
    }

    .overview-footer {
        display: flex;
        justify-content: flex-end;

        button {
            text-transform: none;
            margin-left: 20px;
        }
    }
}

@media (max-width: 1200px) {
    .overview-container {
        height: auto;
    }

    .overview_content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin: auto !important;

        .overview-item {
            margin: 10px 0;
            width: 85%;
            box-sizing: border-box;
            margin-bottom: 10px !important;
        }
    }
}