Fonts
:root {
    --default-font: "Open Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --heading-font: "Montserrat", sans-serif;
    --nav-font: "Lato", sans-serif;
}

/* Global Colors */
:root {
    --background-color: #ffffff;
    --background-color-rgb: 255, 255, 255;
    --default-color: #212529;
    --default-color-rgb: 33, 37, 41;
    --accent-color: #e84545;
    --accent-color-rgb: 232, 69, 69;
    --heading-color: #32353a;
    --heading-color-rgb: 50, 53, 58;
    --contrast-color: #ffffff;
    --contrast-color-rgb: 255, 255, 255;
    --color-secondary: #37373f;
}

/* Nav Menu Colors */
:root {
    --nav-color: #3a3939;
    --nav-hover-color: #e84545;
    --nav-dropdown-color: #3a3939;
    --nav-dropdown-hover-color: #e84545;
    --nav-dropdown-background-color: #ffffff;
}

/* Smooth scroll */
:root {
    scroll-behavior: smooth;
}

/*--------------------------------------------------------------
  # General
  --------------------------------------------------------------*/
body {
    color: var(--default-color);
    background-color: var(--background-color);
    font-family: var(--default-font);
}

a {
    color: var(--accent-color);
    text-decoration: none;
    transition: 0.3s;
}

a:hover {
    color: rgba(var(--accent-color-rgb), 0.7);
    text-decoration: none;
}

.scroll-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 15px;
    bottom: 15px;
    z-index: 99999;
    background-color: var(--accent-color);
    width: 40px;
    height: 40px;
    border-radius: 4px;
    transition: all 0.4s;
}

.scroll-top i {
    font-size: 24px;
    color: #ffffff;
    line-height: 0;
}

.scroll-top:hover {
    background-color: rgba(var(--accent-color-rgb), 0.8);
    color: #ffffff;
}

.scroll-top.active {
    visibility: visible;
    opacity: 1;
}

.btn-scroll {
    transition: 0.9s;
    color: rgba(255, 255, 255, 0.6);
    margin-bottom: 100px;
    animation: up-down 1s ease-in-out infinite alternate-reverse both;
}

.btn-scroll i {
    font-size: 48px;
}

.btn-scroll:hover {
    color: rgb(106, 217, 106);
    text-shadow:
        0 0 5px rgb(0, 255, 98),
        0 0 40px rgb(0, 255, 98);
}

@keyframes up-down {
    0% {
        transform: translateY(5px);
    }

    100% {
        transform: translateY(-5px);
    }
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.header {
    background: #fff;
    transition: all 0.5s;
    z-index: 997;
    height: 85px;
    border-bottom: 1px solid #fff;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.5);
}

@media (max-width: 575px) {
    .header {
        height: 70px;
    }
}

.header.sticked {
    border-color: #fff;
    border-color: #eee;
}


.header .logo h1 {
    font-size: 35px;
    font-weight: 700;
    color: #000;
    margin: 0;
    font-family: var(--font-secondary);
}

.header a:hover {
    color: #ec2727;
    text-decoration: none;
}

.header .logo h1 span {
    color: var(--accent-color);
}

.header .btn-join-us,
.header .btn-join-us:focus {
    font-size: 14px;
    color: #737382;
    padding: 8px 10px;
    border-radius: 5px;
    transition: 0.3s;
    font-weight: 500;
    background: #fff;
    border: solid 1px #d3d3d3;
}

.header .btn-join-us.mobile-only {
    color: #199FD9;
    border-color: #199FD9;
    padding: 6px 15px;
    font-weight: 500;
}

.header .btn-join-us:hover,
.header .btn-join-us:focus:hover {
    color: #199FD9;
    font-weight: 500;
    transition: 0.3s;
    transform: scale(1.05);
    border-color: #199FD9;
}

/*--------------------------------------------------------------
    # Desktop Navigation
--------------------------------------------------------------*/
@media (min-width: 1280px) {
    .navbar {
        padding: 0;
        box-shadow: 0 2px 15px 0 rgba(255, 255, 255, 0.1);
    }

    .navbar ul {
        margin: 0;
        padding: 0;
        display: flex;
        list-style: none;
        align-items: center;
    }

    .navbar li {
        position: relative;
    }

    .navbar>ul>li {
        white-space: nowrap;
        padding: 10px 0 10px 28px;
    }

    .navbar a,
    .navbar a:focus {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 3px;
        font-family: var(--font-secondary);
        font-size: 16px;
        font-weight: 600;
        color: #7f7f90;
        white-space: nowrap;
        transition: 0.3s;
        position: relative;
    }

    .navbar a i,
    .navbar a:focus i {
        font-size: 12px;
        line-height: 0;
        margin-left: 5px;
    }

    .navbar>ul>li>a:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: -6px;
        left: 0;
        background-color: var(--accent-color);
        visibility: hidden;
        width: 0px;
        transition: all 0.3s ease-in-out 0s;
    }

    .navbar a:hover:before,
    .navbar li:hover>a:before,
    .navbar .active:before {
        visibility: visible;
        width: 100%;
    }

    .navbar a:hover,
    .navbar .active,
    .navbar .active:focus,
    .navbar li:hover>a {
        color: #000;
    }

    .navbar .dropdown ul {
        display: block;
        position: absolute;
        left: 28px;
        top: calc(100% + 30px);
        margin: 0;
        padding: 10px 0;
        z-index: 99;
        opacity: 0;
        visibility: hidden;
        background: #fff;
        box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
        transition: 0.3s;
        border-radius: 4px;
    }

    .navbar .dropdown ul li {
        min-width: 200px;
    }

    .navbar .dropdown ul a {
        padding: 10px 20px;
        font-size: 15px;
        text-transform: none;
        font-weight: 600;
    }

    .navbar .dropdown ul a i {
        font-size: 12px;
    }

    .navbar .dropdown ul a:hover,
    .navbar .dropdown ul .active:hover,
    .navbar .dropdown ul li:hover>a {
        color: var(--accent-color);
    }

    .navbar .dropdown:hover>ul {
        opacity: 1;
        top: 100%;
        visibility: visible;
    }

    .navbar .dropdown .dropdown ul {
        top: 0;
        left: calc(100% - 30px);
        visibility: hidden;
    }

    .navbar .dropdown .dropdown:hover>ul {
        opacity: 1;
        top: 0;
        left: 100%;
        visibility: visible;
    }
}

@media (min-width: 1280px) and (max-width: 1366px) {
    .navbar .dropdown .dropdown ul {
        left: -90%;
    }

    .navbar .dropdown .dropdown:hover>ul {
        left: -100%;
    }
}

@media (min-width: 1280px) {

    .mobile-nav-show,
    .mobile-nav-hide {
        display: none;
    }
}

/*--------------------------------------------------------------
    # Mobile Navigation
--------------------------------------------------------------*/
@media (max-width: 1279px) {
    .navbar {
        position: fixed;
        top: 0;
        left: -100%;
        width: 100%;
        max-width: 400px;
        border-left: 1px solid #666;
        bottom: 0;
        transition: 0.3s;
        z-index: 9997;
    }

    .navbar ul {
        position: absolute;
        inset: 0;
        padding: 50px 0 10px 0;
        margin: 0;
        background: rgba(255, 255, 255, 0.9);
        overflow-y: auto;
        transition: 0.3s;
        z-index: 9998;
    }

    .navbar a,
    .navbar a:focus {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px;
        font-family: var(--font-secondary);
        border-bottom: 2px solid rgba(255, 255, 255, 0.8);
        font-size: 16px;
        font-weight: 600;
        color: #7f7f90;
        white-space: nowrap;
        transition: 0.3s;
    }

    .navbar a i,
    .navbar a:focus i {
        font-size: 12px;
        line-height: 0;
        margin-left: 5px;
    }

    .navbar a:hover,
    .navbar li:hover>a {
        color: #000;
    }

    .navbar .active,
    .navbar .active:focus {
        color: #000;
        border-color: var(--accent-color);
    }

    .navbar .dropdown ul,
    .navbar .dropdown .dropdown ul {
        position: static;
        display: none;
        padding: 10px 0;
        margin: 10px 20px;
        transition: all 0.5s ease-in-out;
        border: 1px solid #eee;
    }

    .navbar .dropdown>.dropdown-active,
    .navbar .dropdown .dropdown>.dropdown-active {
        display: block;
    }

    .mobile-nav-toggle {
        color: var(--color-secondary);
        font-size: 28px;
        cursor: pointer;
        line-height: 0;
        transition: 0.5s;
        z-index: 9999;
        margin: 0 10px;
    }

    .mobile-nav-show {
        margin-left: -5px;
    }

    .mobile-nav-hide {
        color: var(--color-secondary);
        font-size: 32px;
        cursor: pointer;
        line-height: 0;
        transition: 0.5s;
        position: fixed;
        right: 20px;
        top: 20px;
        z-index: 9999;
    }

    .mobile-nav-active {
        overflow: hidden;
    }

    .mobile-nav-active .navbar {
        left: 0;
    }

    .mobile-nav-active .navbar:before {
        content: "";
        position: fixed;
        inset: 0;
        background: rgba(255, 255, 255, 0.8);
        z-index: 9996;
    }
}

/*--------------------------------------------------------------
# Loader
--------------------------------------------------------------*/
.loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.loader {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


:root {
    --offset: 187;
    --duration: 1.4s;
}

.loader-container .spinner {
    animation: rotator var(--duration) linear infinite;
}

.loader-container .path {
    stroke-dasharray: var(--offset);
    stroke-dashoffset: 0;
    transform-origin: center;
    animation:
        dash var(--duration) ease-in-out infinite,
        colors calc(var(--duration) * 4) ease-in-out infinite;
}

@keyframes rotator {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(270deg);
    }
}

@keyframes colors {
    0% {
        stroke: #4285F4;
    }

    25% {
        stroke: #DE3E35;
    }

    50% {
        stroke: #F7C223;
    }

    75% {
        stroke: #1B9A59;
    }

    100% {
        stroke: #4285F4;
    }
}

@keyframes dash {
    0% {
        stroke-dashoffset: var(--offset);
    }

    50% {
        stroke-dashoffset: calc(var(--offset) / 4);
        transform: rotate(135deg);
    }

    100% {
        stroke-dashoffset: var(--offset);
        transform: rotate(450deg);
    }
}