/* -------------------------
#Intro details
-------------------------*/
.pill-cust {
    background-color: #eff0f8;
}

.pill-cust .nav-item .nav-link::after {
    display: inline-block;
    content: "";
    border-top: .1rem dashed #000000;
    width: 4rem;
    margin: 2rem 1rem 0;
    transform: translateY(-1rem);
    flex-grow: 1;
}

.nav-pills {
    margin-left: 0;
}

.nav-pills .nav-item a {
    background: #eff0f8;
}

.nav-pills .nav-item a .step-info {
    font-size: 15px;
}

.nav-pills .nav-item a .step-details {
    font-size: 17px;
}

.nav-pills .nav-item .nav-link.active .step-info {
    font-size: 16px;
    font-weight: 600;
}

.nav-pills .nav-item .nav-link.active .step-details {
    font-size: 18px;
    font-weight: 700;
}

.pill-cust .nav-item .nav-link .fa-cust {
    width: 40px;
    height: 40px;
    background: white;
    color: #000000;
}

.pill-cust .nav-item .nav-link.active {
    color: white;
    background-color: transparent;
}

.pill-cust .nav-item .nav-link.active::after {
    border-top: .1rem solid #00296b;
}

.pill-cust .nav-item .nav-link.active .fa-cust {
    background: #00296b;
    color: #ffffff;
}

.pill-cust .nav-item:last-child .nav-link::after {
    display: none;
}

.btn-toolbar button:hover {
    transition: all 0.3s ease-in-out 0s;
    transform: scale(1.1);
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.15);
}

.form-check-input {
    margin-right: 0.5rem;
    transform: scale(1.2);
}

/* styles.css */
.wave-group {
    position: relative;
  }
  
  .wave-group .icon {
    position: absolute;
    top: 36px;
    left: 15px;
    transition: 0.2s ease all;
  }
  
  .wave-group:focus-within .icon {
    margin-top: 12px;
    top: 0;
  }
  
  .wave-group .input {
    font-size: 16px;
    padding: 20px 10px 10px 30px; 
    display: block;
    width: 100%; 
    border: none;
    border-bottom: 1px solid #515151;
    margin-top: 17px; 
  }
  
  .wave-group .input:focus {
    outline: none;
  }
  
  .wave-group .label {
    color: #999;
    font-size: 18px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 40px; /* Adjusted left position */
    top: 35px; /* Adjusted top position */
    display: flex;
  }
  
  .wave-group .label-char {
    transition: 0.2s ease all;
    transition-delay: calc(var(--index) * 0.05s);
  }
  
  .wave-group .input:focus ~ .label .label-char,
  .wave-group .input:valid ~ .label .label-char {
    transform: translateY(-20px);
    font-size: 14px;
    color: #00296b;
  }
  
  .wave-group .bar {
    position: relative;
    display: block;
    width: 100%;
  }
  
  .wave-group .bar:before,
  .wave-group .bar:after {
    content: '';
    height: 2px;
    width: 0;
    bottom: 1px;
    position: absolute;
    background: #00296b;
    transition: 0.2s ease all;
  }
  
  .wave-group .bar:before {
    left: 0;
  }
  
  .wave-group .bar:after {
    right: 0;
  }
  
  .wave-group .input:focus ~ .bar:before,
  .wave-group .input:focus ~ .bar:after {
    width: 50%;
  }


  /* Phone number */
  .ui-wrapper {
    margin: 20px 0px;
    --width: 250px;
    --height: 50px;
    --background: #fff;
    --text-color: rgb(73, 73, 73);
    --border-color: rgb(185, 184, 184);
    --border-focus-color: #00296b;
    --shadow-color: rgba(34, 60, 80, 0.2);
    --shadow-focus-color: rgba(0, 110, 255, 0.2);
    --dropdown-button-color: #00296b;
    --dropdown-button-hover-color: #113f8a;
  }
  
  .ui-wrapper *,
  .ui-wrapper *::before,
  .ui-wrapper *::after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-family: sans-serif;
    color: var(--text-color);
  }
  
  .ui-wrapper {
    width: 100%;
    height: var(--height);
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    border-radius: 10px;
    position: relative;
    border: 1px solid var(--border-color);
    background-color: var(--background);
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-right: 10px;
    -webkit-box-shadow: 0px 2px 5px 0px var(--shadow-color);
    box-shadow: 0px 2px 5px 0px var(--shadow-color);
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  .ui-wrapper>input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
    width: 0;
    height: 0;
    opacity: 0;
    position: absolute;
    left: 9999px;
  }

  .input-wrapper{
    
    padding: 20px;
  }
  legend{
    margin-bottom: 0;
  }
  
  .dropdown-container {
    
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 40px;
    cursor: pointer;
    border-radius: 9px 0 0 9px;
    background-color: var(--dropdown-button-color);
  }
  
  .dropdown-container::before {
    margin-right: 20px;
    content: "🇮🇳";
    font-size: 20px;
    color: white !important;
    background: none !important;
  }
  
  /* .dropdown-container::after {
    content: "";
    color: white;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M7 10l5 5 5-5z'/%3E%3C/svg%3E");
    width: 12px;
    height: 12px;
    background-position: center;
    background-size: cover;
    margin-left: 5px;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
  } */
  
  .dropdown-container svg {
    fill: white;
}


  .select-wrapper {
    width: var(--width);
    position: absolute;
    top: calc(var(--height) + 20px);
    left: 0;
    opacity: 0;
    visibility: hidden;
    max-height: 200px; /* Set a maximum height for the dropdown */
    overflow-y: auto; /* Enable vertical scrolling if necessary */
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
    background-color: white;
    z-index: 1;
    border: 1px solid var(--border-color); /* Add border */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

  
  .select-wrapper ul {
    width: 100%;
    background-color: white;
    border-radius: 10px;
    padding: 10px;
    margin: 0;
    list-style: none;
    height: auto; /* Changed from 300px to auto */
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.5);
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.5);
    overflow-y: auto;
    white-space: nowrap;
}



  


  .select-wrapper ul li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 5px;
    border-radius: 5px;
  }
  
  .select-wrapper ul li label {
    width: 100%;
  }
  
  .select-wrapper ul li,
  .select-wrapper ul li * {
    cursor: pointer;
  }
  
  .select-wrapper ul li:hover {
    background: lightgray;
  }
  
  .select-wrapper ul li span {
    display: inline-block;
    margin-right: 15px;
  }
  
  .input-wrapper {
    width: 100%;
    padding-left: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    row-gap: 2px;
  }
  
  .input-wrapper legend {
    font-size: 11px;
  }
  
  .input-wrapper .textfield {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .input-wrapper .textfield::before {
    content: "+91";
    margin-right: 5px;
    white-space: nowrap;
  }
  
  .input-wrapper .textfield input {
    width: 100%;
    font-size: 16px;
    outline: none;
    border: none;
    background: none;
  }
  
  .invalid-msg {
    font-size: 12px;
    position: absolute;
    color: red;
    top: 115%;
    left: 0;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
  }

  .dropdown-checkbox:checked ~ .select-wrapper {
   
    z-index: 2; 
}
  
  /* actions */
  
  #phonenumber:invalid+.invalid-msg {
    top: 110%;
    opacity: 1;
    visibility: visible;
  }
  
  .ui-wrapper:focus-within {
    border-color: var(--border-focus-color);
    -webkit-box-shadow: 0px 2px 5px 0px var(--shadow-focus-color);
    box-shadow: 0px 2px 5px 0px var(--shadow-focus-color);
  }
  
  .dropdown-container:hover {
    background-color: var(--dropdown-button-hover-color);
  }
  
  .dropdown-checkbox:checked~.select-wrapper {
    top: calc(var(--height) + 5px);
    opacity: 1;
    visibility: visible;
  }
  
  .dropdown-checkbox:checked+.dropdown-container svg {
    rotate: 180deg;
    translate: 0 -2px;
  }
  
  .ui-wrapper input#India:checked~.dropdown-container::before,
  .ui-wrapper input#India:checked~.select-wrapper li.India {
    content: "🇮🇳";
    background-color: lightgray;
  }
  
  .ui-wrapper input#USA:checked~.dropdown-container::before,
  .ui-wrapper input#USA:checked~.select-wrapper li.USA {
    content: "🇺🇸";
    background-color: lightgray;
  }
  
  .ui-wrapper input#Nepal:checked~.dropdown-container::before,
  .ui-wrapper input#Nepal:checked~.select-wrapper li.Nepal {
    content: "🇳🇵";
    background-color: lightgray;
  }
  
  .ui-wrapper input#Pakistan:checked~.dropdown-container::before,
  .ui-wrapper input#Pakistan:checked~.select-wrapper li.Pakistan {
    content: "🇵🇰";
    background-color: lightgray;
  }
  
  .ui-wrapper input#Bangladesh:checked~.dropdown-container::before,
  .ui-wrapper input#Bangladesh:checked~.select-wrapper li.Bangladesh {
    content: "🇧🇩";
    background-color: lightgray;
  }

  .ui-wrapper input#China:checked~.dropdown-container::before,
  .ui-wrapper input#China:checked~.select-wrapper li.China {
    content: "🇨🇳";
    background-color: lightgray;
  }
  
  .ui-wrapper input#Afghanistan:checked~.dropdown-container::before,
  .ui-wrapper input#Afghanistan:checked~.select-wrapper li.Afghanistan {
    content: "🇦🇫";
    background-color: lightgray;
  }

  .ui-wrapper input#Other:checked~.dropdown-container::before,
  .ui-wrapper input#Other:checked~.select-wrapper li.Other {
    content: "🇴🇹";
    background-color: lightgray;
  }
  

  .ui-wrapper input#India:checked~.input-wrapper .textfield::before {
    content: "+91";
  }
  
  .ui-wrapper input#USA:checked~.input-wrapper .textfield::before {
    content: "+1";
  }
  
  .ui-wrapper input#Nepal:checked~.input-wrapper .textfield::before {
    content: "+977";
  }
  
  .ui-wrapper input#Pakistan:checked~.input-wrapper .textfield::before {
    content: "+92";
  }
  
  .ui-wrapper input#Bangladesh:checked~.input-wrapper .textfield::before {
    content: "+880";
  }

  .ui-wrapper input#China:checked~.input-wrapper .textfield::before {
    content: "+86";
  }
  
  .ui-wrapper input#Afghanistan:checked~.input-wrapper .textfield::before {
    content: "+93";
  }
  .ui-wrapper input#Other:checked~.input-wrapper .textfield::before {
    content: "+";
  }
  
  
  @media (max-width: 768px){
    .px-5{
        margin: auto !important;
        padding: 10px !important;
    }
    .dropdown-container{
        padding: 10px 0 10px 15px;
    }
  }

  
  
  