@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&display=swap');

.appointmentcalendar_container {
  display: flex;
  font-family: "Poppins", sans-serif;
}

.calendar_container {
  display: flex;
  width: 100%;
  padding: 20px;
  color: white;
  height: 590px !important;
}

.calendar {
  flex: 2;
  margin-right: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 14px;
  border: 1px solid #ccc;
  padding: 30px;
  display: flex;
  flex-direction: column;
}

/* Adjust StaticDatePicker component size */
.MuiCalendarPicker-root {
  width: 100%;
  height: calc(100% - 100px);
  /* Adjust to fit within the container */
}

.MuiPickersDay-root {
  font-size: 1.2rem;
  /* Increase font size for better visibility */
  width: 40px;
  /* Increase width */
  height: 40px;
  /* Increase height */
  line-height: 40px;
  /* Center text vertically */
}

/* Adjusting the size of the action bar for a consistent look */
.MuiPickersToolbar-root {
  padding: 10px;
  /* Increase padding */
}

.timeslots_container {
  width: 50%;
  background: rgb(0, 41, 107);
  background: linear-gradient(180deg, rgba(0, 41, 107, 1) 23%, rgba(0, 63, 136, 1) 53%, rgba(4, 102, 200, 1) 100%);
  padding: 30px;
  /* Increased padding */
  border-radius: 14px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  height: 550px;
  /* Increased height */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.timeslot_main_heading {
  font-weight: 600;
  margin: 20px;
}

.timeslots {
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  max-height: calc(100% - 80px);
}

.timeslot {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 27%;
  height: 40px;
  background-color: transparent;
  border-radius: 30px;
  border: 1px solid white;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  position: relative;
  margin-top: 25px;
  margin-right: 12px;
  margin-left: 12px;
}

.timeslot.selected {
  background-color: rgba(255, 255, 255, 0.5);
  /* Light background for selected slots */
  color: blue;
}

.timeslot.filled {
  font-weight: 700;
  background-color: white;
  color: #0496ff;
}

.timeslot .tooltip {
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  color: black;
  padding: 5px;
  border-radius: 5px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s;
  font-size: 12px;
  z-index: 10;
}

.timeslot:hover .tooltip {
  opacity: 1;
  visibility: visible;
}

.timeslots_buttons {
  margin-top: 25px;
  display: flex;
  justify-content: space-around;
}

.save_button,
.cancel_button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
  color: white;
}

.save_button {
  background-color: #0496ff;
}

.cancel_button {
  background-color: #ccc;
}

/* button:not(:disabled), [type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled),.css-rhmlg1-MuiTypography-root-MuiDayCalendar-weekDayLabel{
    font-size: 20px !important;
    margin-right: 20px !important;
    margin-bottom: 15px !important;
    margin-top: 15px !important;
}
.css-1rtg91x-MuiDateCalendar-root{
    width: 100% !important; ;
}

.css-dplwbx-MuiPickersCalendarHeader-label{
    color: black !important;;
} */

.css-dplwbx-MuiPickersCalendarHeader-label {
  color: black !important;
  ;
}

.MuiDateCalendar-root {
  width: 100% !important;
  height: 100% !important;
  max-height: none !important;
}

.MuiDayCalendar-weekContainer {
  justify-content: space-around !important;
}

.MuiDayCalendar-header {
  justify-content: space-around !important;
}

.MuiPickersCalendarHeader-root {
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-top: 0 !important;
}

.MuiPickersCalendarHeader-label {
  margin-right: 0 !important;
}

.MuiPickersArrowSwitcher-spacer {
  width: 0 !important;
}

/* Adjust the overall calendar container */
.MuiDateCalendar-root {
  width: 100% !important;
  height: auto !important;
  min-height: 320px !important;
  max-height: none !important;
  padding: 8px !important;
}

/* Increase font size for day numbers and adjust sizing */
.MuiPickersDay-root {
  font-size: 1.2rem !important;
  width: 36px !important;
  height: 36px !important;
  margin: 2px !important;
}

/* Increase font size for weekday labels */
.MuiDayCalendar-weekDayLabel {
  font-size: 1.1rem !important;
  width: 36px !important;
  margin: 2px !important;
}

/* Adjust the week container to prevent scrolling */
.MuiDayCalendar-weekContainer {
  margin: 0 !important;
  justify-content: space-between !important;
}

/* Ensure the month view fills the available space */
.MuiDayCalendar-monthView {
  width: 100% !important;
}

/* Adjust month header font size and spacing */
.MuiPickersCalendarHeader-label {
  font-size: 1.5rem !important;
}

.MuiPickersCalendarHeader-root {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
  padding: 0 8px !important;
}

/* Adjust spacing in the calendar to fill container */
.MuiDayCalendar-header,
.MuiDayCalendar-weekContainer {
  justify-content: space-between !important;
  padding: 0 4px !important;
}

/* Remove any potential scroll on the day picker */
.MuiDayPicker-slideTransition {
  overflow: visible !important;
}


/* @media (max-width: 850px) {
    .calendar_container {
        flex-direction: column;
        width: 90%;
        margin: 20px auto;
        padding: 10px;
    }

    .calendar {
        height: 600px; 
    }

    .timeslot {
        width: calc(50% - 10px);
    }
} */


.savebutton {
  cursor: pointer;
  position: relative;
  padding: 7px 34px;
  font-size: 18px;
  color: white;
  border: 2px solid white;
  border-radius: 34px;
  background-color: rgba(255, 255, 255, 0.295);
  font-weight: 600;
  transition: all 0.3s cubic-bezier(0.23, 1, 0.320, 1);
  overflow: hidden;
}

.savebutton::before {
  content: '';
  position: absolute;
  inset: 0;
  margin: auto;
  width: 50px;
  height: 50px;
  border-radius: inherit;
  scale: 0;
  z-index: -1;
  background-color: #90e0ef;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
}

.savebutton:hover::before {
  scale: 3;
}

.savebutton:hover {
  color: #001d3d;
  scale: 1.1;
  box-shadow: 0 0px 20px rgba(193, 163, 98, 0.4);
}

.savebutton:active {
  scale: 1;
}

.cancelbutton {
  cursor: pointer;
  position: relative;
  padding: 7px 24px;
  font-size: 18px;
  color: white;
  border: 2px solid white;
  border-radius: 34px;
  background-color: rgba(255, 255, 255, 0.295);
  font-weight: 600;
  transition: all 0.3s cubic-bezier(0.23, 1, 0.320, 1);
  overflow: hidden;
}

.cancelbutton::before {
  content: '';
  position: absolute;
  inset: 0;
  margin: auto;
  width: 50px;
  height: 50px;
  border-radius: inherit;
  scale: 0;
  z-index: -1;
  background-color: #ef233c;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
}

.cancelbutton:hover::before {
  scale: 3;
}

.cancelbutton:hover {
  color: white;
  scale: 1.1;
  box-shadow: 0 0px 20px rgba(193, 163, 98, 0.4);
}

.cancelbutton:active {
  scale: 1;
}




@media (max-width: 850px) {
  .calendar_container {
    flex-direction: column;
    width: 90%;
    margin: 20px auto;
    padding: 10px;
  }

  .timeslots_container {
    width: 100%;
    /* margin: auto; */
  }

  .calendar {
    margin-bottom: 20px;
  }

  .timeslot {
    width: 30px;
  }

  .cancelbutton {
    padding: 3px 20px;
    font-size: 15px;
  }

  .savebutton {
    padding: 3px 20px;
    font-size: 15px;
  }

  .calendar {
    padding: 0;
  }

}

@media (max-width: 600px) {

  .MuiPickersDay-root {
    font-size: 1rem !important;
    /* width: 20px !important;
        height: 36px !important;
        margin: 2px !important; */
  }

  .MuiDayCalendar-weekDayLabel {
    font-size: 1.1rem !important;
    width: 20px !important;
    margin: 2px !important;
  }

  .calendar {
    height: 500px;
    /* Further adjust height for very small screens */
  }

  .timeslot {
    width: 50px;
    margin-right: 5px;
    margin-left: 5px;
    font-size: 13px;
    margin-top: 17px;
    align-items: center;
    justify-content: space-around;
  }

  .modal_content {
    width: 90vw;
    padding: 20px;
  }

  .timeslot_main_heading {
    font-size: 20px;
    margin-bottom: 10px;
    margin-top: 5px;
  }
}