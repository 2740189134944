@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

.Consultantcard {
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 400px;
    margin: 20px;
    position: relative;
    text-align: center;
    overflow: visible;
    margin-top: 50px !important;
}

.Consultantcard .circle {
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
    width: 70px;
    height: 70px;
    background-color: #0466c8;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 24px;
    border: 5px solid white;
}

.Consultantcard .circle .initials {
    display: block;
    font-family: "Poppins", sans-serif !important;
    font-weight: 600 !important;
    font-size: 25px !important;
}

.Consultantname,
.Consultantphone {
    font-size: 18px;
    color: #333;
}

.Consultantcard .details {
    margin-top: 20px;
    display: flex;
    cursor: pointer;
}

.Consultantcard .detail-item {
    margin-top: 10px;
    margin-bottom: 8px;
    margin-left: 25px;
}

.Consultantcard .Videoicon {
    margin-top: 15px;
    margin-left: 20px;
    font-size: 30px !important;
    color: #0466c8;
}

.Consultantcard .detail-text {
    font-family: "Poppins", sans-serif !important;
    font-weight: 600 !important;
    color: #333;
    margin-bottom: 5px;
}

.Consultantcard .detail-item .detail-info {
    color: #555;
    font-family: "Poppins", sans-serif !important;
    font-weight: 300 !important;
}

.Consultantcard .name_bg {
    background-color: #c7f0fd;
    padding-bottom: 10px;
    border-radius: 10px;
    box-shadow: 0 0.1em 0.5em rgba(0, 0, 0, 0.3);
    cursor: pointer;
}

.name_bg .Consultantname {
    padding-top: 40px !important;
    font-size: 3.5vh !important;
    letter-spacing: 1.5px !important;
    font-family: "Poppins", sans-serif !important;
    font-weight: 600 !important;
}

.name_bg .Consultantphone {
    padding-top: 10px;
    font-weight: 200;
    letter-spacing: 2px;
    padding-bottom: 10px;
}

.Consultantcard .status-dropdown {
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.Consultantcard .divider {
    margin-bottom: 10px !important;
    border: 0;
    height: 2px;
    background-color: #ddd;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.Consultantcard .status-heading {
    font-family: "Poppins", sans-serif !important;
    font-weight: 300 !important;
    margin-right: 10px;
}

.Consultantcard .payment-failed-text {
    display: flex;
    margin-left: 15px;
    margin-bottom: 13px;
    color: #d90429;
    font-family: "Poppins", sans-serif !important;
    font-weight: 600 !important;
    align-items: flex-start;
}