@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap');

.toggle_card {
  /* position: relative; */
  width: 33.33%;
  margin: 30px;
  margin-top: 0;
  height: auto;
  border-radius: 10px;
  background: white;
  box-shadow: 0 0 0 0.5px rgb(190, 190, 190), 0.2em 0.2em 0.5em rgba(0, 0, 0, 0.3);
  padding: 20px;
}

.toggle-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #333;
}

.flex_toggle {
  display: flex;
  justify-content: center;
}

.toggle-checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.bottom_toggle label {
  display: flex;
  margin-top: 10px;
  margin-bottom: 20px !important;
  justify-content: center;
  width: 100%;
}

.toggle-slot {
  font-size: 10px;
  height: 2.7em;
  justify-content: center;
  width: 6em;
  border: 0px solid transparent;
  border-radius: 10em;
  background-color: white;
  box-shadow: 0 0 0 0.5px rgb(190, 190, 190), 0.2em 0.2em 0.5em rgba(0, 0, 0, 0.3);
  transition: background-color 250ms;
  position: absolute;
}

.toggle-checkbox:checked~.toggle-slot {
  background-color: #55a630;
}

.toggle-button {
  transform: translate(0.3em, 0.25em);
  position: absolute;
  height: 2.3em;
  width: 2.3em;
  border-radius: 50%;
  background-color: #fac3b7;
  box-shadow: inset 0px 0px 0px 0.75em #dc2f02;
  transition: background-color 250ms, border-color 250ms, transform 500ms cubic-bezier(0.26, 2, 0.46, 0.71);

}

.toggle-checkbox:checked~.toggle-slot .toggle-button {
  background-color: #157a24;
  box-shadow: inset 0px 0px 0px 0.75em white;
  transform: translate(3.65em, 0.25em);
}

.sun-icon {
  position: absolute;
  height: 6em;
  width: 6em;
  color: #d00000;
}

.sun-icon-wrapper {
  position: absolute;
  height: 6em;
  width: 6em;
  opacity: 1;
  transform: translate(2em, 2em) rotate(15deg);
  transform-origin: 50% 50%;
  transition: opacity 150ms, transform 500ms cubic-bezier(0.26, 2, 0.46, 0.71);
}

.toggle-checkbox:checked~.toggle-slot .sun-icon-wrapper {
  opacity: 0;
  transform: translate(3em, 2em) rotate(0deg);
}

.moon-icon {
  position: absolute;
  height: 6em;
  width: 6em;
  color: white;
}

.moon-icon-wrapper {
  position: absolute;
  height: 6em;
  width: 6em;
  opacity: 0;
  transform: translate(11em, 2em) rotate(0deg);
  transform-origin: 50% 50%;
  transition: opacity 150ms, transform 500ms cubic-bezier(0.26, 2.5, 0.46, 0.71);
}

.toggle-checkbox:checked~.toggle-slot .moon-icon-wrapper {
  opacity: 1;
  transform: translate(2em, 2em) rotate(-15deg);
}

.inner_toggle {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.top_toggle {
  width: 100%;
  height: 200px;
  border-radius: 10px;
  background: #07b3f7;
  box-shadow: 0 0 0 0.5px rgb(190, 190, 190), 0.2em 0.2em 0.5em rgba(0, 0, 0, 0.3);
  padding: 20px;
  margin-top: 14px;
}

.bottom_toggle {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  width: 100%;
  justify-content: space-between;
}

.first_toggle,
.second_toggle {
  width: auto;
  height: auto;
  border-radius: 10px;
  background: white;
  box-shadow: 0 0 0 0.5px rgb(190, 190, 190), 0.2em 0.2em 0.5em rgba(0, 0, 0, 0.3);
  padding: 15px 10px 30px 15px;
}

.first_toggle {
  margin-right: 30px;
  background-color: #8ecae6;
  width: 100%;
}

.second_toggle {
  background-color: #bae3ff;
  width: 100%;
}

.top_heading,
.info-icon {
  color: white;
}

.info-icon {
  margin-left: 5px;
  cursor: pointer;
  font-size: 10px;
  padding: 2px;
}

.top_heading {
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 550;
  font-style: normal;
}

.heading {
  text-align: center;
  justify-content: center;
  align-items: center;
}

.short {
  font-size: 17px;
  color: #001233;
}

.small_icon {
  padding: 5px;
  margin-left: 0;
  color: #001233;
}

.toggle_button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

/* button */
.toggle_card button {
  --primary-color: #023047;
  --secondary-color: #fff;
  --hover-color: rgb(27, 62, 100);
  --arrow-width: 10px;
  --arrow-stroke: 2px;
  box-sizing: border-box;
  border: 0;
  border-radius: 20px;
  color: var(--secondary-color);
  padding: 0.5em 1.5em;
  background: var(--primary-color);
  display: flex;
  transition: 0.2s background;
  align-items: center;
  gap: 0.6em;
  font-weight: bold;
  margin: auto;
}

button .arrow-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

button .arrow {
  margin-top: 1px;
  width: var(--arrow-width);
  background: var(--primary-color);
  height: var(--arrow-stroke);
  position: relative;
  transition: 0.2s;
}

button .arrow::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  border: solid var(--secondary-color);
  border-width: 0 var(--arrow-stroke) var(--arrow-stroke) 0;
  display: inline-block;
  top: -3px;
  right: 3px;
  transition: 0.2s;
  padding: 3px;
  transform: rotate(-45deg);
}

.toggle_card button:hover {
  background-color: var(--hover-color);
  transition: all 0.3s ease-in-out 0s;
  transform: scale(1.05);
}

button:hover .arrow {
  background: var(--secondary-color);
}

button:hover .arrow:before {
  right: 0;
}


.centered-text {
  text-align: center;
  color: white;
  margin: 20px 0;
  font-size: 17px;
}

.details {
  color: #001233;
  text-align: center;
  margin-bottom: 0;
  margin-top: 15px;
  font-size: 15px;
  padding: 0;
}

@media (maxWidth: 600px) {
  .bottom_toggle {
    flex-direction: column;
    margin-top: 0;
  }

  .toggle_card {
    margin: 15px;
    height: auto;
    width: auto;
  }

  .first_toggle,
  .second_toggle {
    width: 100%;
    margin: 15px 0;

  }

  .top_toggle {
    margin-top: 0;
  }

  .second_toggle {
    margin-top: 0;
    margin-bottom: 0;
  }

}

@media (max-width: 850px) {
  .toggle_card {
    width: 90%;
    margin-top: 20px;
    margin-bottom: 0;
  }
}

@media (max-width: 1100px){
  .toggle_card{
    width: 90%;
  }
}