.sidebar {
  width: 250px;
  background-color: #1b263b;
  min-height: 100vh;
  height: auto !important;
  top: 0;
  left: 0;
  overflow: hidden;
  transition: width 0.3s ease;
  z-index: 10;

  &.collapsed {
    width: 50px; 
    

    .top {
      display: flex;
      justify-content: center; 
      margin-top: 12px;
      .hamburger {
        font-size: 27px; 
        margin: 0; 
        display: block; 
      }
      .logo {
        display: none; 
      }
      .logo-dot {
        display: none; 
      }
    }

    .center {
      .icon {
        margin: 0;
        margin-top: 10px !important;
        font-size: 25px; 
        display: flex;
        justify-content: center; 
        align-items: center; 
        &:hover {
          transition: all 0.3s ease-in-out 0s;
          transform: scale(1.2);
        }
      }
      .title {
        display: none; 
      }
      ul {
        li {
          justify-content: center; 
          padding: 10px 0; 
        }
      }
    }
  }

  .top {
    display: flex;
    align-items: center;
    padding: 15px 20px 5px 20px;

    .hamburger {
      cursor: pointer;
      margin-right: 20px;
      color: white;
    }

    .logo {
      font-size: 30px;
      font-weight: bold;
      color: white;
      margin-left: 10px;
    }

    .logo-dot{
      font-size: 25px;
    }

    .logo .hidden {
      display: none;
    }
  }

  .center {
    padding: 10px;

    .title {
      font-size: 16px;
      font-weight: bold;
      color: white;
      margin-bottom: 10px;
      text-transform: uppercase;
    }

    .title.hidden {
      display: none;
    }

    ul {
      list-style: none;
      padding: 0;

      li {
        display: flex;
        align-items: center;
        padding: 10px;
        cursor: pointer;
        color: white;

        &:hover {
          transition: all 0.3s ease-in-out 0s;
          transform: scale(1.1);
          font-weight: bolder;
        }

        .icon {
          font-size: 20px;
          margin-right: 10px;
        }

        span {
          display: block;
        }

        span.hidden {
          display: none;
        }
      }
    }
  }

  .bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;

    .colorOption {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 1px solid #555;
      margin: 5px;
      cursor: pointer;
    }
  }

  .main_title {
    padding-top: 25px !important;
  }

  .title {
    font-size: 12px !important;
  }

  .logo {
    height: 50px !important;
  }

  @media (max-width: 1024px) {
    .sidebar{
      height: auto !important;
    }

    &.collapsed {
      width: 45px; 
      .center{
      padding: 0 !important;
      }
      
      .top{
        padding: 0;
        margin: 0;
        visibility: hidden;
      }

      .top {
        .hamburger {
          font-size: 16px !important; 
          margin: 0 auto; 
          visibility: hidden;
        }
      }

      .center {
        .icon {
          font-size: 16px !important; 
          margin-top: 15px;
        }

        .title {
          font-size: 10px !important; 
        }

        li {
          padding: 5px; 
          justify-content: center; 
        }
      }
    }
  }
}
