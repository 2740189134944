.Consultantcard .circle {
    width: 110px !important;
    height: 110px !important;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f0f0f0;
    top: -80px;
}

.Consultantcard .profile-pic {
    width: 100%;
    height: auto;
    border-radius: 50%;
}

.Consultantcard .name_bg .Consultantname {
    font-size: 3.5vh !important;
}


.Consultantcard .areas-languages-row {
    display: flex;
    justify-content: space-around;
    gap: 16px;
}

.Consultantcard .circle .profile-pic {
    border: none !important;
}

.Consultantcard .name_bg .Consultantphone {
    padding-bottom: 10px !important;
    padding-top: 0;
}

.Consultantcard .detail-text {
    font-family: "Poppins", sans-serif !important;
    font-weight: 600 !important;
    color: #333 !important;
    margin-bottom: 5px !important;
}

.Consultantcard .detail-item .detail-info {
    color: #555 !important;
    font-family: "Poppins", sans-serif !important;
    font-weight: 300 !important;
}

.bookAgain-button {
    background-color: #007BFF !important;
    color: #fff !important;
    border: 1px solid #0056b3 !important;
    font-family: "Poppins", sans-serif !important;
    font-weight: 600 !important;
    padding: 10px 20px !important;
    margin: 10px 0 !important;
    border-radius: 5px !important;
    cursor: pointer !important;
    transition: all 0.3s !important;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 20px;
    bottom: 10px;
    left: 90px;
}

.bookAgain-button:hover {
    background-color: #0056b3 !important;
    color: #ffffff !important;
    border-color: #003f7f !important;
    transform: translateY(-3px);
}

.bookAgain-text {
    font-weight: 500 !important;
}

.bookAgain-icon {
    margin-right: 8px;
    font-size: 20px !important;
}

@media (max-width: 768px) {
    .bookAgain-button {
        left: 100px;
        right: 10px;
        padding: 8px 16px !important;
    }

    .bookAgain-icon {
        font-size: 18px !important;
    }
}

@media (max-width: 480px) {
    .bookAgain-button {
        left: 60px;
        right: 5px;
        padding: 6px 12px !important;
        font-size: 14px !important;
    }

    .bookAgain-icon {
        font-size: 16px !important;
    }
}