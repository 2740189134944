/* TherapistPage.css */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css");


.user_home {
    display: flex;
}

.user_home .homeContainer {
    flex: 6;
}

.therapist-page-container {
    display: flex;
    flex-wrap: wrap;
    max-width: 1000px;
    margin: 30px auto;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    font-family: "Poppins", sans-serif;
}

.left-section {
    flex: 1;
    padding: 20px;
}

.right-section {
    max-width: 60%;
    flex: 2;
    padding: 20px;
    padding-left: 40px !important;
}

.profile-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
}

.details {
    margin-top: 20px;
}

.details h2 {
    font-size: 18px;
    margin-bottom: 10px;
}

.right-section h1 {
    font-size: 24px;
    margin-bottom: 5px;
}

.status {
    font-size: 14px;
    color: green;
    margin-bottom: 10px;
}

.role {
    font-size: 16px;
    color: #777777;
    margin-bottom: 10px;
}

.ratings {
    margin-bottom: 20px;
    margin-top: 30px;
}

.reviews {
    margin-top: 40px;
}

.right-section .nameheading {
    color: #2b8cd7;
    font-weight: 700;
    font-size: 35px;
}

.divider {
    border-top: 2px solid #d5d5d5;
    margin: 20px 0;
}

.profilebuttons {
    display: inline-block;
    margin: 15px;
    padding: 7px 30px;
    text-align: center;
    font-size: 16px;
    letter-spacing: 1px;
    text-decoration: none;
    color: #2b8cd7;
    background: transparent;
    cursor: pointer;
    transition: ease-out 0.5s;
    border: 2px solid #2b8cd7;
    border-radius: 10px;
    box-shadow: inset 0 0 0 0 #2b8cd7;
    margin-left: 0 !important;
}

.profilebuttons:hover {
    color: white;
    box-shadow: inset 0 -100px 0 0 #2b8cd7;
}

.profilebuttons:active {
    transform: scale(0.9);
}

.ratingHeading {
    font-size: 15px;
    color: #959595;
}

.about-section {
    margin-top: 30px;
    margin-bottom: 20px;
    font-family: "Poppins", sans-serif !important;
}

.about-section .abtheading {
    font-size: 20px;
    font-weight: 600;
    color: #393939;
}

.about-section p {
    color: #555555;
    text-align: justify;
}

.languageandprice {
    display: flex !important;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 20px;
}

.lnghead {
    font-size: 18px;
}

.lngpara {
    font-size: 20px;
    font-weight: 600;
    color: #2b8cd7;
}

.details p {
    color: #2b8cd7 !important;
    margin-bottom: 10px !important;
    font-weight: 600;
}

.details h3 {
    font-size: 16px;
    color: #555555 !important;
}

/* New styles for review card */
.verified_customer_section {
    display: grid;
    grid-template-columns: 1fr;
    border-radius: 10px;
    border: 1px solid #2b8cd7;
    background-color: #e5e5e51a;
    padding: 5px;
    max-width: 350px;
    min-height: 180px;
    margin: 10px;
    margin-left: 0 !important;
    margin-right: 20px !important;
    box-shadow: 9px 10px 20px -10px rgba(0, 0, 0, 0.45);
}

.image_review {
    display: flex;
    gap: 10px;
    padding-top: 10px;
    padding-left: 5px;
}

.image_review i {
    font-size: 16px;
    color: gold;
    margin-left: 0;
    margin-right: 2px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.customer_image {
    max-width: 50px;
    height: 50px;
}

.customer_image img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    float: left;
    margin-right: 10px;
}

.customer_name {
    font-family: Poppins;
    font-weight: 700;
    font-size: 16px;
    margin-top: 0;
    text-align: left;
    color: #2e2a39;
    margin-bottom: 0;
}

.customer_comment {
    padding-left: 5px;
    /* padding-top: 10px; */
    margin-bottom: 0;
    font-size: 13px;
}

.customer_name_review_status {
    display: inline-block;
}

.review-cards-container {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    gap: 15px;
    padding-bottom: 10px;
}

.review-cards-container .reviewcard {
    flex: 0 0 auto;
    scroll-snap-align: start;
    max-width: 350px;
    /* Adjust card width here */
}

/* Existing styles ... */

.review-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 0 10px;
}

.scroll-arrow {
    cursor: pointer;
    font-size: 30px;
    color: #2b8cd7;
}

.review-cards-container {
    display: flex;
    overflow: hidden;
    scroll-snap-type: x mandatory;
    gap: 15px;
    padding-bottom: 10px;
    flex: 1;
}

.reviewcard {
    flex: 0 0 auto;
    scroll-snap-align: start;
    max-width: 350px;
}

.stars {
    display: flex;
    align-items: center;
}

.revheading {
    font-weight: 600;
    font-size: 20px;
}

.detailsPageContainer {
    display: flex;
    background-color: #2b8cd7;
}


@media (max-width: 768px) {
    .therapist-page-container {
        flex-direction: column;
    }

    .verified_customer_section {
        max-width: 75%;
    }

    .right-section {
        margin-top: 70px;
        padding-left: 20px !important;
        max-width: 100% !important;
    }

    .profilebuttons {
        margin-right: 10px !important;
    }
}