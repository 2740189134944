.appointment_card {
    width: 33.33%;
    margin: 40px;
    margin-top: 0;
    margin-left: 0;
    height: auto;
    border-radius: 10px;
    background: white;
    box-shadow: 0 0 0 0.5px rgb(190, 190, 190),
                  0.2em 0.2em 0.5em rgba(0, 0, 0, 0.3);
    padding: 0px;
  }
  
  @media (max-width: 850px) {
    .appointment_card {
      width: 90%;
      margin: 30px auto 30px !important;
    }
  }
  
  .outer-circle {
    width: 24px;
    height: 24px;
    border: 2px solid #1976d2;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .inner-circle {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #1976d2;
  }

  @media (max-width: 1100px){
    .appointment_card{
      width: 90%;
      margin: 10px auto 30px !important;
    }
  }
  
  
  
  
  
  