.user_home {
  display: flex;
}

.user_home .homeContainer {
  flex: 6;
}

.referandearn_container {
  display: flex;
}

.referdetails_cont {
  display: flex;
  width: 70%;
}

.refer_main_cont {
  display: flex;
}

.refer_img {
  width: 50%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 30px;
  margin-right: 30px;
  /* margin-top: 100px; */
}

.step {
  display: flex;
  margin-top: 20px;
  width: 90%;
}

.step p {
  margin: 0;
}

.icon-container {
  background-color: #D2E4F5;
  padding: 10px;
  height: 60px !important;
  width: 60px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}

.icon-container svg {
  width: 33px;
  height: 33px;
  color: #007bff;
}

.details_para_cont {
  display: flex;
  flex-direction: column;
  width: 450px;
  margin-left: 30px;
}

.refer_details {
  margin-top: 100px;
  width: 60%;
  justify-content: center;
  align-items: center;
}

.refer_head {
  font-size: 22px;
  font-weight: 600;
}

.refer_para {
  font-size: 15px;
  color: rgb(161, 161, 161);
}

.refer_mainhead {
  margin-left: 80px;
  font-size: 28px;
  font-weight: 600;
  color: rgb(71, 71, 71);
}

.refrer_mainpara {
  margin-left: 80px;
  font-size: 16px;
  margin-bottom: 50px;
}

.refer_btn {
  appearance: button;
  background-color: #1ba107;
  border: solid transparent;
  border-radius: 12px;
  border-width: 0 0 4px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: .8px;
  line-height: 20px;
  margin: 0;
  outline: none;
  overflow: visible;
  padding: 12px 20px;
  text-align: center;
  touch-action: manipulation;
  transform: translateZ(0);
  transition: filter .2s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  margin-right: 30px;
  margin-top: 50px;
}

.refer_btn:after {
  background-clip: padding-box;
  background-color: #29bf12;
  border: solid transparent;
  border-radius: 16px;
  border-width: 0 0 4px;
  bottom: -4px;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.refer_btn:main,
.refer_btn:focus {
  user-select: auto;
}

.refer_btn:hover:not(:disabled) {
  filter: brightness(1.1);
}

.refer_btn:disabled {
  cursor: auto;
}

.refer_btn:active:after {
  border-width: 0 0 0px;
}

.refer_btn:active {
  padding-bottom: 10px;
}

.share_code_btn {
  background-color: rgb(177, 177, 177);
}

.share_code_btn:after {
  background-color: rgb(202, 202, 202);
}

.whtsapp_icon {
  margin-right: 8px;
}


@media (max-width: 768px) {

  .refer_img {
    width: 100%;
    margin: 20px 0;
  }

  .refer_details {
    width: 100%;
    margin-top: 0;
  }

  .referdetails_cont {
    flex-direction: column;
    width: 90%;
  }

  .refer_img {
    width: 100%;
  }

  .refer_btn {
    padding: 10px 10px;
    margin-right: 5px;
    margin-left: 10px;
  }

  .refer_btn_cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .step {
    margin-left: 12px;
  }
}