.credit_main_cont,
.credit_card_details,
.credit_cards_cont {
  display: flex;
}

.credit_img {
  width: 50%;
  height: 70%;
}

.credit_det_cont {
  width: 90%;
  display: flex;
}

.credit_cards {
  margin-right: 30px;
  padding: 25px;
  border-radius: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.credit_money p {
  margin-bottom: 0;
}

.credit_icon-container {
  background-color: #D2E4F5;
  padding: 10px;
  height: 50px !important;
  width: 50px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  margin-right: 20px;
}

.credit_icon-container svg {
  width: 28px;
  height: 28px;
  color: #007bff;
}

.credit_money h3 {
  font-size: 16px;
  color: rgb(174, 174, 174);
  margin-bottom: 0;
}

.credit_money p {
  color: #007bff;
  font-size: 25px;
  font-weight: 600;
}

.credit_details h2 {
  font-size: 28px;
  margin-bottom: 20px;
  margin-top: 60px;
  font-weight: 600;
  text-align: center;
}

.gift_svg svg {
  color: #2d93ff;
  height: 100px;
  width: 100px;
}

.gift_svg {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cred_bal_head,
.cred_bal_para {
  text-align: center;
}

.cred_bal_head {
  font-size: 18px;
  margin-bottom: 0;
  margin-top: 15px;
}

.cred_bal_para {
  font-size: 30px;
  color: #b8b8b8;
}

.credit_cards_cont {
  margin-left: 15%;
  align-items: center;
  justify-content: center;
}


.transaction-content {
  display: flex;
  align-items: center;
  flex: 1;
}


.transaction-icon svg {
  color: #888;
  /* Customize the icon color */
}


.transaction-details p {
  font-size: 14px;
  margin: 0;
  color: #b0b0b0;
  /* Customize the date color */
}


.transaction-item {
  display: flex;
  align-items: center;
  padding: 10px;
}

.transaction-details {
  flex: 1;
  margin-left: 10px;
}

.recent_head {
  justify-content: space-around;
  display: flex;
}

.recent_head h3 {
  font-size: 20px;
  font-weight: 600;
  margin-left: 2% !important;
  margin-bottom: 0;
}

.recent_head p {
  margin-bottom: 0;
}

.credit_content_wrapper {
  /* width: 90% !important; */
  flex: 6;
}

.credit_top_section {
  display: flex;
}


.transaction-table {
  width: 70%;
  margin: 20px auto;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.transaction-row {
  display: flex;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #eee;
}

.transaction-icon-name {
  display: flex;
  align-items: center;
  width: 40%;
}

.no-credit-history-message {
  text-align: center;
  font-size: 23px !important;
  color: #666;
  padding-top: 40px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Poppins', sans-serif;
}


.transaction-icon {
  background-color: #f0f0f0;
  border-radius: 50%;
  padding: 10px;
  margin-right: 15px;
}

.transaction-icon svg {
  width: 24px;
  height: 24px;
  color: #888;
}

.transaction-details h3 {
  font-size: 16px;
  margin: 0;
  font-weight: 600;
}

.transaction-date {
  text-align: center;
  width: 30%;
  color: #b0b0b0;
}

.transaction-amount-red {
  width: 30%;
  text-align: right;
  color: #d32f2f;
  font-weight: bold;
}

.transaction-amount-green {
  width: 30%;
  text-align: right;
  color: #20ad4d;
  font-weight: bold;
}




@media (max-width: 768px) {
  .credit_top_section {
    flex-direction: column;
  }

  .credit_img {
    width: 100%;
  }

  .credit_cards {
    padding: 10px;
    margin-right: 15px;
    margin-bottom: 30px;
  }

  .credit_cards_cont {
    margin-left: 10px;
  }

  .transaction-table {
    width: 90%;
  }

  .transaction-icon {
    margin-right: 5px;
  }

  .transaction-amount {
    width: 20%;
  }

  .transaction-date {
    text-align: center !important;
    margin-left: 8%;
    width: 60%;
    text-align: right;
  }
}