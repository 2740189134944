.consultant_home {
    display: flex;
    height: 100%;
}

.Consultant_home_container {
    flex: 1;
    width: 90%;
}

.container-content {
    padding: 35px 60px 50px;
}

.compalinace-content {
    background-color: #f8f8f8;
    padding: 55px 70px 100px;
    border-radius: 10px;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
}

.content-container {
    margin: 10px auto;
}

.content-head {
    font-size: 34px;
    font-weight: bold;
    color: #333;
}

.content-separator {
    height: 2px;
    background-color: #ccc;
    margin: 15px 0;
}

.updated-date {
    color: #666;
    font-weight: bold;
}

.content-title {
    color: #555;
    font-size: 1.3rem;
    line-height: 1.9;
    margin-bottom: 0.1rem;
    text-align: justify;
}

.content-text {
    color: #555;
    line-height: 1.6;
    text-align: justify;
}

.list-item {
    margin-bottom: 8px;
}


@media (max-width: 850px) {
    .compalinace-content {
        padding: 20px 30px;
    }

    .container-content {
        padding: 30px 20px;
    }
}