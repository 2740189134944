@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&display=swap');

.container-policy {
    margin: 50px 50px 0;
}

@media (min-width: 1200px) {
    .container-policy {
        margin: -30px 4rem 0rem;
        padding: 0px 3.125rem;
    }
}

@media (max-width: 1200px) {
    .container-policy {
        margin: -30px 0.125rem 0rem;
        padding: 0px 3.125rem;
    }
}

@media (max-width: 992px) {
    .container-policy {
        margin: -30px 1rem 0;
        padding: 0px;
    }
}

@media (max-width: 768px) {
    .container-policy {
        margin: 0px;
        padding: 0px;
    }
}

@media (max-width: 576px) {
    .container-policy {
        margin: 0px;
        padding: 0px;
    }
}

.container-policy .card {
    box-shadow: 0 20px 27px 0 rgb(0 0 0 / 5%);
    border: none;
    margin-bottom: 0px;
    padding-bottom: 20px;
    border-radius: 20px;
}

.privacy_image_container {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    padding-top: 50px;
    padding-bottom: 20px;
    font-family: "Poppins", sans-serif;
    margin-top: 60px;
    background-color: #e1e4e7;
}

.privacy_text {
    flex: 1;
    padding-right: 100px;
    padding-left: 50px;
}

.privacy_heading {
    font-size: 2rem;
    margin-bottom: 0.8rem;
    color: black;
    font-weight: 700 !important;
}

.privacy_paragraph {
    font-size: 1rem;
    color: #666;
}

.container-policy p {
    text-align: justify;
}

.container-policy li {
    text-align: justify;
}

.privacy_image {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e1e4e7;
    height: 400px;
    flex-shrink: 0;
    padding-right: 25px;
    padding-left: 100px;
}

.privacy_image img {
    height: 100%;
    object-fit: cover;

}

.privacypolicy_details_container {
    font-family: "Poppins", sans-serif;
}

.common_privacy_policy_para {
    text-align: center;
}

.privacypolicy_details_heading {
    border-bottom: 4px solid #558bff;
    font-size: 30px;
    font-weight: 600;
    padding-bottom: 5px;
    margin-bottom: 30px;
    text-align: center;
}

.privacypolicy_details_heading .logo-dot {
    color: #e84545;
}

.privacy_first_para {
    margin-top: 30px;
    text-align: center;
    font-size: 17px;
    font-weight: 500;
}

.common_privacy_policy_para {
    padding: 30px 140px;

}

.privacypolicy_points {
    padding: 20px 50px;
}

.privacypolicy_points_heading {
    border-bottom: 3px solid #558bff;
    font-size: 25px;
    color: rgb(36, 35, 35);
    font-weight: 600;
}

.privacypolicy_points_number {
    color: #ffbe0b;
    font-size: 28px;
    font-weight: 700;
}

.privacypolicy_points p {
    margin-top: 20px;
    font-size: 16px;
    color: rgb(81, 81, 81);
}

.privacypolicy_points li {
    color: rgb(81, 81, 81);
    padding-left: 30px;
}

.custom-list {
    list-style-type: none;
    padding: 0;
}

.custom-list li {
    position: relative;
    padding-left: 1.5em;
    margin-bottom: 0.5em;
}

.custom-list li::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0.5em;
    width: 0.6em;
    height: 0.6em;
    background-color: #6294ff;
    border-radius: 50%;
}

.custom-list li::after {
    content: '';
    position: absolute;
    left: 0.15em;
    top: 0.65em;
    width: 0.3em;
    height: 0.3em;
    background-color: white;
    border-radius: 50%;
}

.custom-list li span {
    color: rgb(68, 68, 68);
    font-size: 16px;
    font-weight: 600;
}

.list_spacing {
    margin-top: 30px;
}

@media (max-width: 768px) {
    .privacy_image_container {
        flex-direction: column;
    }

    .privacy_text {
        padding-right: 20px;
        padding-left: 20px;
        margin-top: 7%;
        margin-bottom: 10px;
        text-align: center;
    }

    .privacy_image {
        height: 350px;
        width: 100%;
        padding-right: 20px;
        padding-left: 20px;
        margin-top: 25px;
        padding-bottom: 50px;
    }

    .privacy_image img {
        width: 100%;
        height: auto;
        margin-top: 40px;
    }

    .common_privacy_policy_para {
        padding: 0 30px;

    }

    .privacy_first_para {
        font-size: 15px;
    }

    .privacypolicy_points {
        padding: 20px 15px;
    }
}