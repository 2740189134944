@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&display=swap');

/* General Container Styles */
.dwnld_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f5f7fa;
  font-family: "Poppins", sans-serif;
}

.app_image {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.app_image img {
  max-width: auto;
  height: 80vh;
  border-radius: 20px;
}

.app_text {
  width: 100%;
  text-align: center;
}

.app_text h1 {
  font-size: 2.5rem;
  margin-bottom: 30px;
  font-weight: 700;
  color: #001d3d;
}

.app_text p {
  font-size: 1.125rem;
  color: #555555;
  margin-bottom: 50px;
}

.download_link_section {
  margin-bottom: 20px;
}

.download_link_section h3 {
  font-size: 1.2rem;
  color: #262c4e;
  margin-bottom: 10px;
  font-weight: 600;
}

.phone_input_container {
  display: flex;
  justify-content: center;
}

.country_code_input {
  max-width: 75px;
  border-top-right-radius: 2px !important;
  border-bottom-right-radius: 2px !important;
}

.phone_input {
  max-width: 250px;
  border-top-left-radius: 2px !important;
  border-bottom-left-radius: 2px !important;
}

.country_code_input,
.phone_input {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: auto;
  flex-grow: 0;
}

.phone_input {
  flex-grow: 1;
}

.send_sms_button {
  background-color: #00a6fb !important;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
}

.send_sms_button:hover {
  background-color: #0ba0ea !important;
}

.app_text .highlight_text {
  color: #00a6fb;
  font-weight: 800;
  font-size: 3.4rem !important;
}

.app_text .highlight_dot {
  color: #e84545;
}

/* Button Styles */
.download_buttons {
  display: flex;
  gap: 10px;
  margin-top: 40px;
}

.Btn {
  width: 160px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #03070f; */
  border: 1px solid black;
  /* color: white; */
  font-weight: 600;
  gap: 8px;
  cursor: pointer;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.103);
  position: relative;
  overflow: hidden;
  transition-duration: 0.3s;
  border-radius: 3px;
  flex-direction: row;
  text-align: center;
}

.Btn .svgIcon {
  width: 24px;
  height: 24px;
  transition: fill 0.3s;
}

.Btn::before {
  width: 160px;
  height: 160px;
  position: absolute;
  content: "";
  background-color: white;
  border-radius: 10px !important;
  left: -102%;
  top: 0;
  transition: transform 0.3s;
  transition-duration: 0.3s;
  mix-blend-mode: difference;
}

.Btn:hover::before {
  transition-duration: 0.3s;
  transform: translate(100%, -50%);
  /* border-radius: 5px !important; */
}

.Btn:hover::before {
  transform: translate(100%, -50%);
}

.Btn:active {
  transform: translate(5px, 5px);
}

button.send_sms_button {
  font-family: inherit;
  font-size: 16px;
  background: #111637;
  color: white;
  padding: 0.4em 1.2em;
  padding-left: 0.9em;
  border: none;
  border-radius: 7px;
  overflow: hidden;
  transition: all 0.2s;
  cursor: pointer;
}

button.send_sms_button span {
  display: block;
  margin-left: 0.3em;
  transition: all 0.3s ease-in-out;
}

button.send_sms_button svg {
  display: block;
  transform-origin: center center;
  transition: transform 0.3s ease-in-out;
}

button.send_sms_button:hover .svg-wrapper {
  animation: fly-1 0.6s ease-in-out infinite alternate;
}

button.send_sms_button:hover svg {
  transform: translateX(1.2em) rotate(45deg) scale(1.1);
}

button.send_sms_button:hover span {
  transform: translateX(5em);
}

button.send_sms_button:active {
  transform: scale(0.95);
}

.download_buttons .google_play_button:hover {
  transition: all 0.3s ease-in-out 0s;
  transform: scale(1.08);
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.5);
}

@keyframes fly-1 {
  from {
    transform: translateY(0.1em);
  }

  to {
    transform: translateY(-0.1em);
  }
}

/* Responsive Styles */
@media (min-width: 768px) {
  .dwnld_container {
    flex-direction: row;
    justify-content: center;
  }

  .app_image {
    width: 40%;
    margin-bottom: 0;
  }

  .app_text {
    width: 50%;
    text-align: left;
    padding-left: 50px;
  }

  .app_text h1 {
    font-size: 3rem;
  }

  .app_text p {
    font-size: 1.25rem;
  }

  .download_link_section {
    text-align: left;
  }

  .phone_input_container {
    justify-content: flex-start;
  }

  .send_sms_button {
    margin-left: 10px;
  }
}

@media (max-width: 768px) {
  .country_code_input {
    max-width: 50px !important;
  }

  .phone_input {
    max-width: 175px !important;
    margin-right: 20px;
  }

  .phone_input_container {
    margin-bottom: 30px;
  }

  .download_buttons {
    margin-bottom: 50px !important;
    align-items: center !important;
    justify-content: center !important;
    ;
  }
}