@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&display=swap');

.faq_container {
    display: flex;
    background-color: white;
    min-height: 100vh;
    font-family: "Poppins", sans-serif;
    margin-top: 50px;
}

.faq_content {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.faq_text_image_container {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.faq_text {
    flex: 1;
    padding-right: 100px;
    padding-left: 50px;
}

.faq_heading {
    font-size: 2rem;
    margin-bottom: 0.8rem;
    color: black;
    font-weight: 700 !important;
}

.faq_paragraph {
    font-size: 1rem;
    color: #666;
}

.faq_image {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e1e4e7;
    height: 400px;
    flex-shrink: 0;
    padding-right: 25px;
    padding-left: 100px;
}

.faq_text_image_container {
    background-color: #e1e4e7;
}

.faq_image img {
    height: 100%;
    object-fit: cover;
}

@media (max-width: 600px) {
    .faq_text_image_container {
        flex-direction: column;
    }

    .faq_text {
        padding-right: 20px;
        padding-left: 20px;
        margin-top: 50px;
        margin-bottom: 10px;
        text-align: center;
    }

    .faq_image {
        height: 200px;
        width: 100%;
        padding-right: 20px;
        padding-left: 20px;
        margin-top: 80px;
        padding-bottom: 50px;
    }

    .faq_image img {
        width: 100%;
        height: auto;
    }
}

/*--------------------------------------------------------------
# FAQ Section
--------------------------------------------------------------*/
.container-faq {
    margin: 0px;
    padding: 0px;
}

.container-faq .card {
    box-shadow: 0 20px 27px 0 rgb(0 0 0 / 5%);
    border: none;
    margin-bottom: 0px;
    padding-bottom: 20px;
    border-radius: 20px;
}

.container-faq .card p {
    text-align: justify;
}

.container-faq .nav-tabs {
    border-bottom: 1px solid #dee2e6;
}

.container-faq .nav-item .nav-link {
    width: 100%;
}

.container-faq .nav-tabs-custom .nav-item .nav-link.active {
    color: #6c6ff5;
    border-bottom: 1px solid #dee2e6;
}

.container-faq .font-size-18 {
    font-size: 18px;
    text-transform: none;
    font-weight: 400;
}

.container-faq .nav-tabs-custom {
    margin-top: -20px;
}

.container-faq .nav-tabs-custom .nav-item .nav-link {
    color: #0d6efd;
    border-bottom: 1px solid #dee2e6;
}

.container-faq .text-muted {
    color: #6c7681 !important;
}

.container-faq .nav-tabs .nav-link:hover {
    background-color: white;
}

.container-faq .nav-tabs-custom .nav-item {
    position: relative;
    color: #271050;
}

.container-faq .nav-tabs-custom .nav-item .nav-link.active:after {
    -webkit-transform: scale(1);
    transform: scale(1);
}

.container-faq .nav-tabs-custom .nav-item .nav-link::after {
    content: "";
    background: #6c6ff5;
    height: 2px;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -1px;
    -webkit-transition: all 250ms ease 0s;
    transition: all 250ms ease 0s;
    -webkit-transform: scale(0);
    transform: scale(0);
}

.accordion-style .card {
    background: transparent;
    box-shadow: none;
    margin-bottom: 15px;
    margin-top: 0 !important;
    border: none;
}

.accordion-style .card:last-child {
    margin-bottom: 0;
}

.accordion-style .card-header {
    border: 0;
    background: none;
    padding: 0;
    border-bottom: none;
}

.accordion-style .btn-link {
    color: #ffffff;
    position: relative;
    background: #15395a;
    border: 1px solid #15395a;
    display: block;
    width: 100%;
    font-size: 18px;
    border-radius: 3px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    text-align: left;
    white-space: normal;
    box-shadow: none;
    padding: 15px 55px;
    text-decoration: none;
}

.accordion-style .btn-link:hover {
    text-decoration: none;
}

.accordion-style .btn-link.collapsed {
    background: #ffffff;
    border: 1px solid #15395a;
    color: #1e2022;
    border-radius: 3px;
}

.accordion-style .btn-link.collapsed:after {
    background: none;
    border-radius: 3px;
    content: "+";
    left: 16px;
    right: inherit;
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
    height: 26px;
    transform: none;
    width: 26px;
    top: 15px;
    text-align: center;
    background-color: #15395a;
    color: #fff;
}

.accordion-style .btn-link:after {
    background: #fff;
    border: none;
    border-radius: 3px;
    content: "-";
    left: 16px;
    right: inherit;
    font-size: 20px;
    font-weight: 600;
    height: 26px;
    line-height: 26px;
    transform: none;
    width: 26px;
    top: 15px;
    position: absolute;
    color: #15395a;
    text-align: center;
}

.accordion-style .card-body {
    padding: 20px;
    border-top: none;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    border-left: 1px solid #15395a;
    border-right: 1px solid #15395a;
    border-bottom: 1px solid #15395a;
    text-align: justify;
}

@media screen and (max-width: 767px) {
    .accordion-style .btn-link {
        padding: 15px 40px 15px 55px;
    }
}

@media screen and (max-width: 575px) {
    .accordion-style .btn-link {
        padding: 15px 20px 15px 55px;
    }
}

.card-style1 {
    box-shadow: 0px 0px 10px 0px rgb(89 75 128 / 9%);
}

.border-0 {
    border: 0 !important;
}

.accordion-style .card-accord {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: 0.25rem;
}

.mb-2-3,
.my-2-3 {
    margin-bottom: 2.3rem;
}